<template>
<!--  <div >-->
    <table id="wrapper_side_box_layout_prod" style="page-break-after:always;">
      <tbody class="LayoutSideBoxNomenclature"
             v-if="$store.state.smallSize"
      >
      <tr>
        <td v-for="(row, index) in arr_data_order" :key="index">
          <div class="LayoutSideBoxNomenclature-td">
            <div class="LayoutSideBoxNomenclature-column1">
              <img :src="$store.state.VUE_DEV_LOCAL_SERVER + getValidSrcImage(row.ALL_DOC.PROD_DOCUMENTS_CONTOUR)" class="LayoutSideBoxNomenclature-column1-preview"/>
              <barcode
                  v-if="row.BARCODE"
                  v-bind:value="row.BARCODE"
                  class="LayoutSideBoxNomenclature-column1-barcode"
                  :height="45"
                  :width="1.65"
                  :font-size="12"
              >
                Ошибка отрисовки штрих-кода
              </barcode>
              <span v-else class="LayoutBarCode_barcode_error" style="text-align: center;text-decoration: underline;color: red;">
                Не назначен штрихкод!
              </span>
            </div>

            <div class="LayoutSideBoxNomenclature-column2">
            <img :src="'/img/icons/logo_GIDRUSS.svg'" class="LayoutSideBoxNomenclature-column2-logo"/>
            <section class="LayoutSideBoxNomenclature-column2-category">{{ row.SINGULAR_NAME_CATEGORY }}</section>
            <section class="LayoutSideBoxNomenclature-column2-name">{{ row.SHORT_NAME }}</section>
            <section class="LayoutSideBoxNomenclature-column2-article">Артикул: {{ row.ARTICUL }}</section>
            <section class="LayoutSideBoxNomenclature-column2-wrapper-underline">
              <span>зав. №</span>
              <span class="LayoutSideBoxNomenclature-column2-underline">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </section>

            <!-- Основные характеристики -->
            <div class="LayoutSideBoxNomenclature-column2-characteristics">
              <section class="LayoutSideBoxNomenclature-column2-characteristics-row">
                <span>Длина, мм:</span>
                <span></span>
                <span>{{ (row.LENGTH) ? row.LENGTH : '-' }}</span>
              </section>
              <section class="LayoutSideBoxNomenclature-column2-characteristics-row">
                <span>Ширина, мм:</span>
                <span></span>
                <span>{{ (row.WIDTH) ? row.WIDTH : '-' }}</span>
              </section>
              <section class="LayoutSideBoxNomenclature-column2-characteristics-row">
                <span>Высота, мм:</span>
                <span></span>
                <span>{{ (row.HEIGHT) ? row.HEIGHT : '-' }}</span>
              </section>
              <section class="LayoutSideBoxNomenclature-column2-characteristics-row">
                <span>Масса брутто, кг:</span>
                <span></span>
                <span>{{ (row.GROSS_WEIGHT) ? row.GROSS_WEIGHT : '-' }}</span>
              </section>
            </div>
          </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
</template>

<script>
    import barcode from "vue-barcode";

    export default {
        name: "LayoutSideBoxNomenclature",
      components: {barcode},
        props: ['id_prod_from_parent', 'detailNomenclature'],
        data: () => ({
            arr_data_order: []
        }),
        mounted() {
          this.loadData()
        },
        computed: {
        },
        methods:{
          getValidSrcImage(string){
            if (!string) return '';
            if (!string.SRC) return '';

            return string.SRC;
          },
          INTERNAL_ARTICLE(data) {
            return data?.INTERNAL_ARTICLE ? data.INTERNAL_ARTICLE : ''
          },
          NAME_DATA(data) {
            return data?.NAME ? data.NAME : ''
          },
          refactorDataToTables(data) {
            const arr = [[[],[]],[[],[]]]
            const refactorArr = []
            let row = []
            const column = []

            for (let i = 0; i < data.length; i++) {
              if ((i+1) % 2 !== 0) {
                row.push(data[i])
              } else {
                row.push(data[i])
                this.arr_data_order.push(row)
                row = []
              }
            }
            this.print()
          },
          loadData(){
            const _this = this;
            console.log(this.id_prod_from_parent)
            BX.ajax.runComponentAction('nk:production.print_layout',
                'executeGetDataProd', { // Вызывается без постфикса Action
                  mode: 'class',
                  data: {
                    'post':  {
                      'id_prod': (!!_this.id_prod_from_parent) ? _this.id_prod_from_parent : _this.$route.query.id_prod,
                    },
                    'login': _this.$store.state.login,
                    'token': _this.$store.state.token,
                  },
                })
                .then(function(response) {
                  console.log('then loadData response');
                  console.log(response);
                  if (response.status === 'success') {
                    _this.refactorDataToTables(response.data)
                    _this.arr_data_order = response.data;
                    // console.log(_this.arr_data_order)
                    // _this.print();
                  }
                })
                .catch((response) => {
                  console.log(response);
                });
          },
          print(){
            this.$nextTick(() => {

              const main_vue_component = this.$root.$children.find(child => { return child.$options.name === "Purchase_order"; });
              main_vue_component.printed_setTimeout(this, '#wrapper_side_box_layout_prod');

              // w.close();
              //     // console.log(this.arr_data_order);
              //     this.$htmlToPaper('print_layout_prod', null, () => {
              //         console.log('Printing completed or was cancelled!');
              //         this.$emit('printing-completed')
              //     });
            })
          }
        }
    }
</script>

<style lang="scss">
    //@import "../../public/print_layout.css";

    /* TODO стиди неактуальны смотри в файле Print_layout.css*/
    /* Стили для печатной формы Этикеток продукции */
    @media print {
      @page {
        size: auto;
        margin: 0;
        padding: 0;
      }
      header {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      .LayoutSideBoxNomenclature {
        display: table;
        grid-template-columns: 35% auto;
        margin-left: 10px;
      }
      .LayoutSideBoxNomenclature-td{
        display: grid;
        grid-column-gap: 15px;
        grid-template-columns: 40% 1fr;
        height: 244px;
        max-height: 244px;
        padding-top: 15px;
        padding-right: 15px;
      }
      .LayoutSideBoxNomenclature-column1 {
        display: grid;
        grid-row-gap: 10px;
        align-items: end;
        grid-template-rows: 1fr auto;
      }
      .LayoutSideBoxNomenclature-column1-preview{
        max-height: 160px;
        max-width: 170px;
        width: auto;
        height: auto;
        margin: auto;
      }
      .vue-barcode-element{
        width: 100%;
      }
      .LayoutSideBoxNomenclature-column1-barcode{
        text-align: center;
      }
      .LayoutSideBoxNomenclature-column2 {

        display: grid;
        grid-row-gap: 10px;
        justify-items: baseline;
        font-size: 14px;
        margin-top: 40px;
      }
      .LayoutSideBoxNomenclature-column2-category{
        font-size: 15px;
      }
      .LayoutSideBoxNomenclature-column2-name{
        font-size: 15px;
      }
      .LayoutSideBoxNomenclature-column2-article{
        font-size: 17px;
        font-weight: bold;
      }
      .LayoutSideBoxNomenclature-column2-logo{
        width: 140px;
        height: auto;
        margin-left: auto;
        position: absolute;
        right: 10px;
        top: -55px;
      }
      .LayoutSideBoxNomenclature-column2-wrapper-underline{
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-column-gap: 5px;
      }
      .LayoutSideBoxNomenclature-column2-underline{
        border-bottom: 1px solid black;
        width: 100%;
      }
      .LayoutSideBoxNomenclature-column2-characteristics{
        display: grid;
        grid-column-gap: 15px;
        font-size: 11.2px;
        width: 100%;
        height: 60px;
      }
      .LayoutSideBoxNomenclature-column2-characteristics-row{
        display: grid;
        grid-column-gap: 5px;
        grid-template-columns: auto 1fr auto;
      }
      .LayoutSideBoxNomenclature-column2-characteristics-row span:first-child{
        margin-right: auto;
      }
      .LayoutSideBoxNomenclature-column2-characteristics-row span:nth-child(2){
        border-bottom: 1px dashed;
        width: 100%;
      }
      .LayoutSideBoxNomenclature-column2-characteristics-row span:last-child{
        margin-left: auto;
      }
    }
</style>
