<template>
  <v-app id="app" class="app">
    <div id="nav">
      <!--<router-link to="/">Home</router-link> |-->
      <!--<router-link to="/about">About</router-link>-->
      <v-app-bar height="50px" dark app class="header_bar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <router-link to="/" style="text-decoration: none">
          <v-icon large style="margin: 0 8px 0 0"> mdi-home-outline </v-icon>
        </router-link>
        <v-toolbar-title> {{ GetArticleName }} </v-toolbar-title>
        <div v-if="showIcons">
          <HeaderIcons />
        </div>

        <v-icon id="icon_reload_with_cache" @click="reload_with_cache()"
          >fas fa-sync-alt</v-icon
        >
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        temporary
        dark
        value
        app
        style="height: 100% !important"
      >
        <v-list-item v-if="!!userGetter && userGetter.length !== 0">
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ userGetter[0].MAIN_USER_GROUP_USER_SHORT_NAME }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-for="user in userGetter"
              :key="user.GROUP_ID"
            >
              {{ user.MAIN_USER_GROUP_GROUP_NAME }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
                <v-list-item
                    v-for="(tab, index) in $store.state.data_access_user_group_user
                    .ACCESS_USER_GROUP_DESKTOP_ACTION"
                    :key="index"
                    link
                    active-class="router-link-exact-active"
                    class="tab"
                    @click="onTouch(tab)"
                    :class="{disabled: ! tab.state}"
                >
                  <v-tooltip bottom :disabled="tab.state">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-content v-bind="attrs" v-on="on">
                        {{ tab.name }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <img
                            :src="require(`@/assets/svg/${tab.src}`)"
                            alt=""
                            class="tab_container__img"
                        />
                      </v-list-item-icon>
                    </template>
                    <span> {{ (!tab.state) ? 'В разработке' : '' }} </span>
                  </v-tooltip>
                </v-list-item>
            <!--            <v-list-item
              v-for="tab in $router.options.routes"
              v-if="tab.props.visible_front"
              :key="tab.id"
              :to="tab.path"
              link
              active-class="router-link-exact-active"
              :disabled="tab.props.check_access_cur_user === 'N'"
            >
              {{ tab.props.ru_name }}
            </v-list-item>-->
          </v-list-item-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <div style="color: darkgray;font-size: 8px;">v{{ VERSION() }}</div>
            <v-btn color="secondary" block @click="logout()"> Выйти </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <!-- <template v-if="$store.getters.isLoggedIn">
            <router-link v-for="tab in list_tabs"
                        :key="tab.id"
                        :id="tab.id"
                        :to="tab.href"
                        :class="{'router-link-exact-active': tab.isActive}"
                        v-on:click.native="computed_isActiveTab()"
            >
                {{ tab.name }}
            </router-link>
          </template> -->
      <!--<span v-if="$store.getters.isLoggedIn"> | <a @click="logout()">Logout</a></span>-->
    </div>
    <v-dialog
      v-model="userinfo"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <div v-if="!!userGetter && userGetter.length !== 0">
          <div>
            {{ userGetter[0].MAIN_USER_GROUP_USER_SHORT_NAME }}
          </div>
          <div v-for="user in userGetter" :key="user.GROUP_ID">
            {{ user.MAIN_USER_GROUP_GROUP_NAME }}
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view />
    </v-main>
    <custom-footer v-if="$route.name !== 'DealersNomenclaturesQuantity'"/>
    <div style="height: 47px"></div>

    <!-- Диалоговое окно с оповещениями об ошибках -->
    <system-notification-dialog></system-notification-dialog>
    <simple-modal/>
  </v-app>
</template>

<script>
import bx_core from './assets/script/core'
import { mapGetters } from 'vuex'
import customFooter from './components/CustomFooter'
import SystemNotificationDialog from './components/SystemNotificationDialog'
import HeaderIcons from './components/specification/HeaderIcons'
import SimpleModal from "@/components/ModalWindow/SimpleModal";
import {VERSION} from "./main";

export default {
  components: {
    SimpleModal,
    customFooter,
    bx_core,
    SystemNotificationDialog,
    HeaderIcons,
  },
  name: 'Purchase_order',
  data: () => ({
    userinfo: false,
    color: 'deep-purple accent-4',
    drawer: false,
    group: null,
    isActiveLink: '',
    shouldShow: false,
  }),
  mounted() {
    BX.bitrix_sessid = () => { return this.$store.state.token; }
    /* Если обновляем страничку вызываем */
    this.run_first_data_after_auth()
    console.log('PROCESS', process, process.env.BASE_URL)
    if (!this.$store.state.list_type_products.length) {
      this.$store.dispatch('setListTypeProductsAction')
    }
  },
  created: function () {
    const _this = this

    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpdateUI = true
      })
    }

    // window.addEventListener("beforeunload", this.leaving);
    // console.log(this.$axios.interceptors);
    this.$axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err
      })
    })
  },
  watch: {
    /* Смотрим как только авторизовался, запрашиваем основные */
    '$store.getters.isLoggedIn': {
      handler: function watch() {
        this.run_first_data_after_auth()
      },
    },
    '$route.query': {
      handler: function watch() {
        if (this.shouldShow) {
          this.$store.commit('setStateAddNewOrder', true)
          this.shouldShow = false
        }
      },
      deep: true,
    },
    group() {
      this.drawer = false
    },
  },
  computed: {
    ...mapGetters(['tokenGetter', 'userGetter']),
    GetArticleName() {
      if (this.$route.name !== null) {
        return this.$store.getters.getCurrentRouteData(this.$route.name).props
          .ru_name
      }
    },

    showIcons() {
      if (
        this.$route.name === 'SpecificationView' ||
        this.$route.name === 'SpecificationCreate' ||
        this.$route.name === 'SpecificationEdit'
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    VERSION() {
      return VERSION
    },
    /* Навигация /окрытие модалки/ */
    onTouch(value) {
      function NewObj(value) {
        this.stage = value.queryParams?.ID_STAGE
        this.scenario = value.queryParams?.ID_SCENARIO
        this.subtype_prod = value.queryParams?.XML_ID_PROP
      }

      if (value.href === null) {
        this.shouldShow = true
        let queryData = Object.assign({}, { querys: new NewObj(value) })
        console.log(queryData)
        this.$store.commit('changeShouldUpdateUrl', true)
        this.$store.dispatch('getUpdatedURL', queryData)
      } else {
        console.log(value)
        let queryData = Object.assign(
          {},
          { querys: new NewObj(value) },
          { path: value.href },
        )
        console.log(queryData)
        this.routerPush(queryData)
      }
    },
    // Обновляем URL
    routerPush(object) {
      this.$store.commit('updateURl_desktop', object)
    },

    reload_with_cache() {
      location.reload(true)
    },
		run_first_data_after_auth() {
			this.$store.dispatch('SetAccessUserGroup').then(() => {
				console.log('dispatch SetAccessUserGroup')
				this.denied_functional()
			})
			this.$store.dispatch('SetGlobalVariables')

      if (this.$store.getters.isLoggedIn === true) {
        if (Object.keys(this.$store.state.data_production).length === 0) {
          this.get_data_production()
        }
      }
    },
    denied_functional() {
      const _this = this

      this.$router.options.routes.forEach(function (elem) {
        elem.props.check_access_cur_user = 'Y'
        if (elem.name === 'Receipt_overhead' || elem.name === 'Shortage') {
					elem.props.check_access_cur_user =
						_this.$store.state.data_access_user_group_user.ACCESS_USER_GROUP_VISIBLE_TAB_RECEIPT_OVERHEAD.CHECK_VALID_USER_GROUP
        }
        if (elem.name === 'ListRemains') {
					elem.props.check_access_cur_user =
						_this.$store.state.data_access_user_group_user.ACCESS_USER_GROUP_VISIBLE_TAB_AMOUNT.CHECK_VALID_USER_GROUP
        }
        if (elem.name === 'ListProductOnStore') {
					elem.props.check_access_cur_user =
						_this.$store.state.data_access_user_group_user.ACCESS_USER_GROUP_VISIBLE_TAB_LIST_PRODUCT_ON_STORE.CHECK_VALID_USER_GROUP
        }
      })
    },

    async get_data_production() {
			console.log('Vuex SetDataProduction')
			this.$store.dispatch('SetDataProduction')
    },

    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },

    printed_setTimeout(_this, id_html_selector) {
      _this.$store.state.printing_file = true

      const w = window.open('/', 'print_page')
      w.onload = function () {
        w.document.write(
          '<link media="print" rel="stylesheet" href="/print_style.css"/>',
        )
        w.document.write(
          '<link media="print" rel="stylesheet" href="/print_layout.css"/>',
        )
        w.document.write(
          '<link media="print" rel="stylesheet" href="/print_layout_side_box.css"/>',
        )
        w.document.write(
          '<link media="print" rel="stylesheet" href="/print_barcode.css"/>',
        )
        w.document.write(
          '<link media="print" rel="stylesheet" href="/print_image_product_card.css"/>',
        )
        w.document.write(document.querySelector(id_html_selector).innerHTML)
        w.focus()

        setTimeout(function () {
          w.print()
        }, 2000)

        setTimeout(() => {
          _this.$store.state.printing_file = false
          // w.close();
        }, 4000)
      }
    },
  },
  beforeDestroyed() {
    // window.onunload = () => {
    //   window.localStorage.clear()
    // }
    // this.logout()
  },
}
</script>

<style lang="scss">
@import './assets/sass/main';

body {
  @extend %padding_20_percent_media_768;
  background: #363636;
  //background: blue;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  //margin-bottom: 4em;
  .header_bar {
    max-width: 900px;
    margin: auto;
    z-index: 999;
    #icon_reload_with_cache {
      //position: absolute;
      //right: 15px;
    }
    .v-toolbar__content {
      display: flex;
      align-items: center;
    }
    .v-toolbar__title {
      flex: 1 1;
      display: grid;
      height: 100%;
      align-items: center;
      justify-items: start;
      padding: unset;
    }
  }
  .fixed_wrapper {
    position: sticky;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    min-height: 43px;
    a {
      font-weight: 400;
      color: white;
      background: $background_static_tabs;
      text-decoration: unset;
      font-size: 0.9em;
      height: inherit;
      display: grid;
      align-items: center;
      padding: 10px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      &.router-link-exact-active {
        background: #363636;
      }
    }
  }

  .v-navigation-drawer {
    width: 300px !important;
    font-size: 16px;
    z-index: 99999999;
    .v-list-item {
      text-align: left;
      &.disabled{
        color: grey;
        cursor: not-allowed;
      }
    }
    .v-list-item__icon {
      align-items: center !important;
    }
  }
  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tab_container__img {
      width: 28px;
      height: 28px;
    }
  }
}
html{
  overflow-y: scroll!important;
}
</style>
