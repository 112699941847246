<template>
  <div
      :class="{modalView: modalView}"
  >
    <section class="display">
      <img class="image"
           :src="$store.state.VUE_DEV_LOCAL_SERVER+product.PICTURE.SRC"
           :title="product.PICTURE.TITLE"
           :width="product.PICTURE.WIDTH"
           @error="replaceByDefault"
      >
    </section>
    <section class="wrapper_name_articul">
      <a @click="open_dialog_nomenclature(product.PRODUCT_ID)" class="href_to_nomenclature">
        <section class="name_prod" :data-id-prod="product.PRODUCT_ID">{{ product.NAME }}</section>
      </a>
        <section class="articul">
        Арт: <span>{{ product.INTERNAL_ARTICLE ? product.INTERNAL_ARTICLE : product.ARTICUL }}</span>
      </section>
<section style="display: flex;
    flex-direction: row;
    flex-wrap: nowrap;">

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          :loading="openPrint"
          min-width="40px"
          max-width="40px"
          style="margin-right: 5px"
          @click="onPrint()"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon size="32" class="mdi mdi-qrcode"></v-icon>
      </v-btn>
    </template>
    <span>Печать этикетки</span>
  </v-tooltip>

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-if="product.BARCODE"
          :loading="openPrint"
          min-width="30px"
          max-width="40px"
          style="margin-right: 5px"
          @click="onPrintBarCode()"
          v-bind="attrs"
          v-on="on"

      >
        <v-icon size="32" class="mdi mdi-barcode"></v-icon>
      </v-btn>
      <v-btn
          v-else

          min-width="40px"
          max-width="40px"
          style="margin-right: 5px"
          disabled
          v-bind="attrs"
          v-on="on"
      >
        <v-icon large class="mdi mdi-barcode-off"></v-icon>
      </v-btn>
    </template>
    <span v-if="product.BARCODE">Печать штрихкода</span>
    <span v-if="!product.BARCODE">Штрихкод не назначен</span>
  </v-tooltip>

  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-btn
            :loading="openPrint"
            min-width="40px"
            max-width="40px"
            style="margin-right: 5px"
            @click="onPrintSideBoxLayout()"
            :disabled="!product.BARCODE || !product.STATE_PROD_DOCUMENTS_CONTOUR"
        >
          <v-icon size="32" class="mdi mdi-package-variant"></v-icon>
        </v-btn>
      </div>
    </template>
    <span>{{ getMessageErrorSideLayout(product) }}</span>
  </v-tooltip>


</section>

      <template v-if="false">
        <v-menu offset-y dark z-index="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
                class="button_print_detail_order"
                :loading="openPrint"
            >
              <v-icon class="print_detail_order"
                      :style="'background-image: url('+$store.state.VUE_DEV_LOCAL_SERVER+'/local/templates/gidruss/static/svg/Принтер.svg)'"

              ></v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                  min-width="200px"
                  @click="onPrint()"
              >Печать этикетки
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn
                  v-if="product.BARCODE"
                  min-width="200px"
                  @click="onPrintBarCode()"
              >Печать штрихкода
              </v-btn>
              <v-btn
                  min-width="200px"
                  v-else
                  disabled
              >
                Штрихкод не назначен
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </section>
    <div class="wrapper_quantity">
      <div class="wrapper_quantity__left">
        <v-text-field
            hide-details
            class="EDITABLE_QUANTITY"
            v-model="product.QUANTITY_TO_ORDER_DEFAULT"
            min="0"
            type="number"
            :loading="((!!product.data_update_commentary) && product.data_update_commentary.code === 'send')"
            disabled
        ></v-text-field>
        <div class="wrapper_quantity__left__remains">
          <div class="wrapper_quantity__left__remains__block">
            <span>ФО:</span>
            <v-text-field
                :solo="!product.editable"
                disabled
                :flat="!product.editable"
                hide-details
                class="EDITABLE_QUANTITY_REMAINS"
                height="20px"
                type="number"
                v-model="product.AMOUNT"
            >
            </v-text-field>
          </div>
          <div class="wrapper_quantity__left__remains__block">
            <span>КО:</span>
            <v-text-field
                :solo="!product.editable"
                :disabled="!product.editable"
                :flat="!product.editable"
                hide-details
                class="EDITABLE_QUANTITY_REMAINS"
                height="20px"
                type="number"
                v-model="product.CRITICAL_QUANTITY_GIDRUSS"
                :class="{edit: product.editable}"
                @input="onChangeProperty({'id_elem': product.PRODUCT_ID, 'code_prop':'CRITICAL_QUANTITY_GIDRUSS', 'value_prop': product.CRITICAL_QUANTITY_GIDRUSS})"
                :loading="$store.state.listNomenclatureByContractors.loading_input"
            >
            </v-text-field>
          </div>
          <div class="wrapper_quantity__left__remains__block">
            <span>НО:</span>
            <v-text-field
                :solo="!product.editable"
                :disabled="!product.editable"
                :flat="!product.editable"
                hide-details
                class="EDITABLE_QUANTITY_REMAINS"
                height="20px"
                type="number"
                v-model="product.MINIMUM_QUANTITY_GIDRUSS"
                :class="{edit: product.editable}"
                @input="$store.dispatch('setPropertyItemAnyIblock', {'id_elem': product.PRODUCT_ID, 'code_prop':'MINIMUM_QUANTITY_GIDRUSS', 'value_prop': product.MINIMUM_QUANTITY_GIDRUSS})"
                :loading="$store.state.listNomenclatureByContractors.loading_input"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <div class="wrapper_quantity__center">
        <v-text-field
            hide-details
            class="EDITABLE_QUANTITY"
            v-model="product.QUANTITY_TO_ORDER"
            min="0"
            type="number"
            :loading="((!!product.data_update_commentary) && product.data_update_commentary.code === 'send')"
        >
          <template slot="prepend-inner">
            <v-icon small class="EDITABLE_QUANTITY__prepend" @click="onChangeQuantity('minus', product)">
              mdi-minus
            </v-icon>
          </template>
          <template slot="append">
            <v-icon small class="EDITABLE_QUANTITY__append" @click="onChangeQuantity('plus', product)">
              mdi-plus
            </v-icon>
          </template>
        </v-text-field>
        <div class="wrapper_quantity__center__package wrapper_quantity__center__remains__block">
          <span> В упаковке: </span>
          <v-text-field
              :solo="!product.editable"
              :disabled="!product.editable"
              :flat="!product.editable"
              hide-details
              class="EDITABLE_QUANTITY_REMAINS"
              height="20px"
              type="number"
              v-model="product.HOW_MANY_PIECES_IN_PACKAGE"
              :class="{edit: product.editable}"
              @input="$store.dispatch('setPropertyItemAnyIblock', {'id_elem': product.PRODUCT_ID, 'code_prop':'HOW_MANY_PIECES_IN_PACKAGE', 'value_prop': product.HOW_MANY_PIECES_IN_PACKAGE})"
              :loading="$store.state.listNomenclatureByContractors.loading_input"
          >
          </v-text-field>
          <span>шт.</span>
        </div>
        <template v-if="!nomenclatureListComponent">
          <v-icon
              class="wrapper_quantity__center__edit"
              @click="product.editable = !product.editable"

          >
            mdi-pencil-box-outline
          </v-icon>
        </template>
<!--        <template v-else>
          <div style="display: flex; justify-content: center; align-items: center; column-gap: 15px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="wrapper_quantity__center__edit"
                    v-bind="attrs"
                    v-on="on"
                    @click="openNomenclature('5', product.PRODUCT_ID, 'producer')"
                >
                  mdi-account-cog
                </v-icon>
              </template>
              <span>Привязать к производителю</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="wrapper_quantity__center__edit"
                    v-bind="attrs"
                    v-on="on"
                    @click="openNomenclature('5', product.PRODUCT_ID, 'contractors_by_default')"
                >
                  mdi-account-plus
                </v-icon>
              </template>
              <span>Привязать к поставщику по умолчанию</span>
            </v-tooltip>
          </div>
        </template>-->
      </div>
      <div class="wrapper_quantity__right">
            <span class="wrapper_quantity__right__total" :class="{showTotal: !!product.total}">
              {{ getTotal(product) }} ₽
            </span>
        <span class="wrapper_quantity__right__per_item">
              {{ product.PURCHASING_PRICE }} ₽/шт.
            </span>
      </div>
    </div>

    <!-- МОДАЛКИ -->
    <!-- Уведомление о копировании -->

    <!-- Компонент печати этикетки ЗН-->
    <layout-nomenclature
        v-show="false"
        v-if="print_layout_nomenclature && !!product"
        :detail-nomenclature="product"
        :id_prod_from_parent="product.ID"
    >
    </layout-nomenclature>

    <!-- Компонент печати штрих-кода ЗН-->
    <layout-bar-code
        v-show="false"
        v-if="print_bar_code_nomenclature && !!product"
        :detail-nomenclature="product"
        :id_prod_from_parent="product.ID"
    >
    </layout-bar-code>

    <!-- Компонент печати боковой наклейки -->
    <layout-side-box-nomenclature
        v-show="false"
        v-if="print_layout_side_box_nomenclature && !!product"
        :detail-nomenclature="product"
        :id_prod_from_parent="product.ID"
    >
    </layout-side-box-nomenclature>
  </div>
</template>

<script>
import LayoutNomenclature from "./LayoutNomenclature";
import Contractors from "../../views/Contractors";
import LayoutBarCode from "@/components/nomenclature/LayoutBarCode";
import LayoutSideBoxNomenclature from "@/components/nomenclature/LayoutSideBoxNomenclature.vue";

export default {
  components: {LayoutSideBoxNomenclature, LayoutBarCode, Contractors, LayoutNomenclature},
  props: ['product', 'modalView', 'nomenclatureListComponent'],
  name: "NomenclatureHorizontalCard",
  data: () => ({
    dialog_nomenclature_window: {
      state: false,
      id_order: null,
    },
    debounceTimeout: null,
    debounceTotal: null,
    requestObj: {},
    data_filters: {
      color: [],
    },
    print_layout_nomenclature: false,
    print_layout_side_box_nomenclature: false,
    print_bar_code_nomenclature: false,
    openPrint: false,
  }),
  mounted() {
  },
  computed: {},
  watch: {
  },
  methods: {
    openNomenclature(tab, order_id, action) {
      this.$store.state.tabContractor = action
      this.$store.state.state_block_filters = true
      this.$emit('openDetailNomenclature', order_id)
      this.$router.push({hash: `tab-${tab}`})
    },
    getListNomenclature() {
      console.log('this.requestObj');
      console.log(this.requestObj);
      // this.$store.dispatch('getListNomenclature', {})
    },
    /* Общая функция для изменения свойств */
    onChangeProperty(property) {
      this.$store.dispatch('setPropertyItemAnyIblock', property).then(() => {
        this.getListNomenclature()
      })
    },
    replaceByDefault(e) {
      e.target.src = require(`@/assets/svg/default.jpg`)
    },
    onChangeQuantity(operation, product) {
      const planned_quantity = (!!product.QUANTITY_TO_ORDER) ? product.QUANTITY_TO_ORDER : 0;
      const pieces_in_package = (!!product.HOW_MANY_PIECES_IN_PACKAGE) ? product.HOW_MANY_PIECES_IN_PACKAGE : 0;

      if (!!pieces_in_package) {
        /* Остаток */
        const aliquot = planned_quantity % pieces_in_package;
        /* Вычисляем логику декремента / инкремента от остатков / упаковки */
        const decrement_aliquout = parseInt((!!aliquot) ? aliquot : pieces_in_package);
        const increment_aliquout = pieces_in_package - aliquot;

        if (operation === 'minus' && !!planned_quantity) {
          product.QUANTITY_TO_ORDER = parseInt(planned_quantity) - parseInt(decrement_aliquout);
          this.changeValue(product)
        }
        if (operation === 'plus') {
          product.QUANTITY_TO_ORDER = parseInt(planned_quantity) + parseInt(increment_aliquout);
          this.changeValue(product)
        }
      } else {
        if (operation === 'minus' && !!planned_quantity) {
          product.QUANTITY_TO_ORDER = parseInt(product.QUANTITY_TO_ORDER) - 1
          this.changeValue(product)
        }
        if (operation === 'plus') {
          product.QUANTITY_TO_ORDER = parseInt(product.QUANTITY_TO_ORDER) + 1
          this.changeValue(product)
        }
      }
    },
    changeValue(product) {
      console.log('changed!')

      if (this.debounceTotal) clearTimeout(this.debounceTotal)

      this.debounceTotal = setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch('setTotalCard', product)
        })
      }, 500)
    },
    open_dialog_nomenclature(order_id) {
      this.$store.state.dialog_nomenclature_window = true
      this.$emit('openDetailNomenclature', order_id)
      this.$store.state.NomenclatureModal = this.product
    },
    getTotal(product) {
      return product.total = parseInt(product.perProd) * parseInt(product.counter)
    },
    printNomenclature(id) {
      this.$store.dispatch('getNomenclatureById', id)

    },
    onPrint() {
      this.print_layout_nomenclature = !this.print_layout_nomenclature
      this.openPrint = this.print_layout_nomenclature
      setTimeout(() => {
        this.openPrint = false
        this.print_layout_nomenclature = false
      }, 5000)
    },
    onPrintSideBoxLayout() {
      this.print_layout_side_box_nomenclature = !this.print_layout_side_box_nomenclature
      this.openPrint = this.print_layout_side_box_nomenclature
      setTimeout(() => {
        this.openPrint = false
        this.print_layout_side_box_nomenclature = false
      }, 5000)
    },
    onPrintBarCode() {
      this.print_bar_code_nomenclature = !this.print_bar_code_nomenclature
      this.openPrint = this.print_bar_code_nomenclature
      setTimeout(() => {
        this.openPrint = false
        this.print_bar_code_nomenclature = false
      }, 5000)
    },
    getMessageErrorSideLayout(product){
      if (! product.BARCODE) return 'Не сгенерирован штрихкод';
      if (! product.STATE_PROD_DOCUMENTS_CONTOUR) return 'Не залит SVG рисунок - "ERP: Контур с ТЛ"';
      return 'Печать боковой наклейки';
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/main";

%howManyPiecesGrid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: end;
  grid-column-gap: 5px;
}

.row_remains {
  display: grid;
  grid-template-columns: 25% 30% auto;
  grid-column-gap: 0.75em;
  align-items: center;
  background: $background_main_order_block;
  @extend %padding-row-position;
  @extend %margin-row-position;
  @extend %border-universal;
  //border-color: map-get($border-universal-color, 'желтый');
  font-size: 1em;
  position: relative;

  .image {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
    border-radius: 5px;
    background-color: #FFFFFF;
  }

  .display {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 130px;
    max-width: 100%;
  }

  .wrapper_name_articul {
    align-self: start;
    text-align: left;
    display: grid;
    height: 100%;
    align-content: space-around;
    grid-row-gap: 5px;
    color: lightgrey;

    .name_prod {
      font-size: 0.9em;
      cursor: pointer;
    }

    .articul, .drawing_number {
      font-size: 1.1em;
      color: grey;

      .button_print_detail_order {
        //width: 100%;
        //height: 100%;

        .print_detail_order {
          background-size: contain;
          height: 32px;
          width: 32px;
          background-position: center;
        }
      }

      span {
        color: lightgrey;
        font-size: 1em;
        opacity: 0.9;
      }
    }
  }

  .wrapper_quantity {
    display: grid;
    grid-template-columns: 25% auto 20%;
    align-items: start;
    word-break: break-word;
    column-gap: 15px;
    transition: all .3s ease;

    &__right {
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: all .3s ease;
      font-size: 0.75em;

      &__total {
        padding: 8px 0;
        font-weight: 500;
        color: lightyellow;
        transform: translateY(30px);
        transition: all .3s ease;
        opacity: 0;
      }

      .showTotal {
        opacity: 1;
      }

      &__per_item {
        opacity: 0;
        transform: translateY(30px);
        transition: all .3s ease;
      }
    }

    &__center {
      display: flex;
      flex-direction: column;
      transition: all .3s ease;
      row-gap: 5px;
      font-size: 0.75em;
      //height: 100%;
      .EDITABLE_QUANTITY {
        transform: translateY(30px);
        transition: all .3s ease;
        -moz-appearance: textfield !important;

        ::v-deep input::-webkit-outer-spin-button,
        ::v-deep input::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
        }

        ::v-deep .v-input__prepend-inner {
          align-self: center !important;
        }

        ::v-deep .v-input__append-inner {
          align-self: center !important;
        }

        &__append {
          cursor: pointer !important;
          color: #FFFFFF;
        }

        &__prepend {
          cursor: pointer !important;
          color: #FFFFFF;
        }
      }

      &__package {
        height: 30px;
        opacity: 0;
        transform: translateY(30px);
        transition: all .3s ease;
        @extend %howManyPiecesGrid;
      }

      &__edit {
        color: white;
        opacity: 0;
        transform: translateY(30px);
        transition: transform .3s ease, opacity .3s ease, color .4s ease-in-out;
        cursor: pointer;

        &:hover {
          color: darkgray;
        }

        ::v-deep .v-icon.v-icon::before {
          background-color: transparent !important;
        }
      }

      &__remains {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        opacity: 0;
        transform: translateY(30px);
        transition: all .3s ease;
        row-gap: 5px;
        margin-left: -28px;

        &__block {
          height: 24px;
          span {
            min-width: 23px;
          }

          .EDITABLE_QUANTITY_REMAINS {
            max-width: 70px;
            -moz-appearance: textfield !important;

            ::v-deep .v-input__control input {
              color: darkgray !important;
              max-height: 24px !important;
              text-indent: 8px;
            }

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-text-field__suffix {
              font-size: 12px !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__slot {
              background: $background_main_order_block !important;
              padding: 0;
            }

            ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
            ::v-deep .v-input__slot {
              margin: 0;
              max-height: 26px;
              min-height: 24px !important;
              display: flex !important;
              flex-direction: column;
              align-items: center !important;
            }

            ::v-deep .v-input__control {
              min-height: 24px !important;
              color: white;
            }
          }

          .edit {
            ::v-deep .v-input__slot {
              background: white !important;
              padding: 0;
            }

            ::v-deep .v-input__control input {
              color: black !important;
              text-indent: 8px;
              text-align: left;
            }
          }
        }
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      font-size: 0.75em;

      .EDITABLE_QUANTITY {
        transform: translateY(30px);
        transition: all .3s ease;
        padding-bottom: 5px;
        -moz-appearance: textfield !important;

        ::v-deep input::-webkit-outer-spin-button,
        ::v-deep input::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
        }

        ::v-deep .v-input__prepend-inner {
          align-self: center !important;
        }

        ::v-deep .v-input__append-inner {
          align-self: center !important;
        }

        &__append {
          cursor: pointer !important;
          color: #FFFFFF;
        }

        &__prepend {
          cursor: pointer !important;
          color: #FFFFFF;
        }
      }

      &__remains {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        opacity: 0;
        transform: translateY(30px);
        transition: all .3s ease;
        row-gap: 5px;
        margin-left: -28px;

        &__block {
          display: flex;
          column-gap: 5px;
          justify-content: flex-start;
          align-items: center;
          height: 24px;
          padding-left: 30px;
          span {
            min-width: 23px;
          }

          .EDITABLE_QUANTITY_REMAINS {
            max-width: 70px;
            -moz-appearance: textfield !important;

            //::v-deep .v-text-field input {
            //  color: darkgray !important;
            //}

            ::v-deep .v-input__control input {
              color: darkgray !important;
              max-height: 24px !important;
              text-indent: 8px;
            }

            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }

            ::v-deep .v-input__prepend-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__append-inner {
              align-self: center !important;
            }

            ::v-deep .v-input__slot {
              background: $background_main_order_block !important;
              padding: 0;
            }

            ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
            ::v-deep .v-input__slot {
              margin: 0;
              max-height: 26px;
              min-height: 24px !important;
              display: flex !important;
              flex-direction: column;
              align-items: center !important;
            }

            ::v-deep .v-input__control {
              min-height: 24px !important;
              color: white;
            }
          }

          .edit {
            ::v-deep .v-input__slot {
              background: white !important;
              padding: 0;
            }

            ::v-deep .v-input__control input {
              color: black !important;
              text-indent: 8px;
              text-align: left;
            }
          }
        }
      }
    }
  }

  .card-buttons {
    position: absolute;
    right: -15px;
    top: -5px;

    &__delete {
      background: lightcoral;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.6s ease-in-out;
      cursor: pointer;
    }

    .showCloseImg {
      opacity: 1;
    }
  }

  &:hover {
    .wrapper_quantity {
      align-items: start;

      &__right {
        &__per_item {
          opacity: 1;
          transform: translateY(0px);
        }

        &__total {
          transform: translateY(0px);
        }
      }

      &__center {
        .EDITABLE_QUANTITY {
          transform: translateY(0px);
        }

        &__package {
          opacity: 1;
          transform: translateY(0px);
          @extend %howManyPiecesGrid;
        }

        &__edit {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      &__left {
        .EDITABLE_QUANTITY {
          transform: translateY(0px);
        }

        &__remains {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}

.modalView {
  .row_remains {
    font-size: 24px !important;
  }
}

.row_remains_modal {
  grid-template-columns: 20% 30% auto;

  .display {
    height: 110px;
  }
}
</style>
