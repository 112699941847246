<template>
  <div class="dealers-container">
    <v-card class="mx-auto" max-width="1200">
      <v-card-text class="wrapper_data">
        <div class="tree-wrapper">
          <v-text-field
              v-model="search"
              label="Поиск"
              dense
              hide-details
              clearable
              outlined
              clear-icon="mdi-close-circle-outline"
          ></v-text-field>
          <v-treeview
              :items="formattedTree"
              :search="search"
              item-key="name"
              :open.sync="open"
              class="customTreeViewDNQ"
          >
            <template v-slot:label="{ item }">
              <span v-if="!item.isDealer">
                <a @click="clickHere(item.id)">
                  <span>{{ item.name }}</span>
                  <span v-if="item.count !== undefined" class="dealer-count-mark">{{ item.count }}</span>
                </a>
              </span>
              <span v-else>
                <a :href="'https://gidruss.ru/company/dealers/' + item.code + '/'" target="_blank" class="external-link">
                  <TooltipStyled
                      :title="'Открыть карточку дилера'"
                  >
                      {{ item.name }}
                  </TooltipStyled>
                </a>
              </span>
            </template>
          </v-treeview>
        </div>
        <div class="dealers-wrapper">
          <div class="dealer-info">
            <div class="search-wrapper">
              <v-switch hide-details v-model="showZeroValues" label="Отобразить дилеров без наличия" class="ma-2 customSwitch"></v-switch>
              <v-combobox
                  dense
                  clearable
                  multiple
                  small-chips
                  outlined
                  hide-details
                  deletable-chips
                  v-model="selectedProducts"
                  label="Выбрать товары"
                  placeholder="Введите название товара"
                  class="customCombobox"
                  @change="getDealersBySection()"
              >
              </v-combobox>
            </div>

            <v-data-table
                v-if="$store.getters.getStateByGroupId(1)"
                :headers="headers"
                :items="filteredDealersBySection"
                :loading="isLoadingTable"
            >
              <template v-slot:default>
                <v-divider></v-divider>
              </template>
              <template v-slot:item="{ item, index }">
                <tr v-if="showZeroValues || item.SUMM_PRICE !== 0">
                  <td>{{ index + 1 }}</td>
                  <td class="name-dealer">
                    <div class="name-menu-container">
                      <a :href="item.URL_DEALERS" target="_blank">
                      <TooltipStyled
                          :title="'Открыть карточку дилера'"
                      >
                        {{ item.NAME }}
                      </TooltipStyled>
                      </a>
                    </div>
                  </td>
                  <td>{{ item.COUNT }}
                    <v-menu
                        offset-y
                        :nudge-left="240"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="getDealerProducts(item.ID)" class="button-right-align">
                          <TooltipStyled :title="'Посмотреть остатки с учётом выбранных товаров'">
                            <v-icon>mdi-calendar-text</v-icon>
                          </TooltipStyled>
                        </v-btn>
                      </template>
                      <v-card @click.stop>
                        <v-card-text>
                          <v-data-table
                              dense
                              :headers="tableHeaders"
                              :items="dealerProducts"
                              item-key="PRODUCT_ID"
                              class="inner-table"
                          >
                            <template v-slot:item="{ item }">
                              <tr>
                                <td>{{ item.PRODUCT_NAME }}</td>
                                <td>{{ item.QUANTITY_VAL !== null ? parseInt(item.QUANTITY_VAL) : 0 }}</td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </td>
                  <td>{{ item.SUMM_PRICE }} ₽</td>
                  <td>
                    <a :href="item.ADMIN_URL" target="_blank">
                      <TooltipStyled
                          :title="'Редактировать карточку дилера'"
                      >
                        <v-icon>mdi-pencil-circle-outline</v-icon>
                      </TooltipStyled>
                    </a>
                  </td>
                </tr>
              </template>
              <template v-slot:footer>
                <tr>
                  <td class="total-label">Всего в закупе:</td>
                  <td class="total-cell">{{ getTotalSummPrice() }} ₽</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Request from "../services/request";
import TooltipStyled from "@/components/Common/TooltipStyled.vue";

export default {
  name: "DealersNomenclaturesQuantity",
  components: {TooltipStyled},
  data() {
    return {
      cities: [],
      countryDealers: [],
      dealerProducts: [],
      selectedCity: null,
      selectedItemId: null,
      showZeroValues: true,
      search: '',
      searchProduct: '',
      open: [], // Открытые элементы дерева
      dealersBySection: [],
      headers: [
        { text: '№', value: 'index' },
        { text: 'Наименование дилера', value: 'NAME' },
        { text: 'Кол-во SQU', value: 'COUNT' },
        { text: 'Сумма в закупе', value: 'SUMM_PRICE' },
        { text: 'Действия', value: 'ADMIN_URL' }
      ],
      tableHeaders: [
        { text: 'Наименование товара', value: 'PRODUCT_NAME' },
        { text: 'Количество', value: 'QUANTITY_VAL' },
        // Добавьте остальные заголовки по необходимости
      ],
      selectedProducts: [],
      isLoadingTable: false,
    };
  },
  computed: {
    formattedTree() {
      const tree = [];
      // Убираем зависимость от dealersBySection, если нет фильтрации по продукту
      let dealerIds = [];
      if (this.selectedProducts.length > 0) {
        // Подразумевается, что dealersBySection уже отфильтрован по продуктам
        dealerIds = this.dealersBySection.map(dealer => dealer.ID);
      }

      Object.values(this.countryDealers).forEach(country => {
        let countryCount = 0;
        const countryNode = {
          id: country.country.ID,
          name: country.country.NAME,
          children: []
        };

        Object.values(country.regions).forEach(region => {
          let regionCount = 0;
          const regionNode = {
            id: region.region.ID,
            name: region.region.NAME,
            children: []
          };

          Object.values(region.cities).forEach(city => {
            let cityDealers = city.dealers;

            // Если есть фильтрация по продукту, используем отфильтрованный список дилеров
            if (this.selectedProducts.length > 0) {
              cityDealers = cityDealers.filter(dealer => dealerIds.includes(dealer.ID));
            }

            const cityCount = cityDealers.length;
            if (cityCount > 0) {
              regionCount += cityCount;
              countryCount += cityCount;
              const cityNode = {
                id: city.city.ID,
                name: city.city.NAME,
                count: cityCount,
                children: cityDealers.map(dealer => ({
                  id: dealer.ID,
                  name: dealer.NAME,
                  code: dealer.CODE,
                  isDealer: true
                }))
              };
              regionNode.children.push(cityNode);
            }
          });

          if (regionCount > 0) {
            regionNode.count = regionCount;
            countryNode.children.push(regionNode);
          }
        });

        if (countryCount > 0) {
          countryNode.count = countryCount;
          tree.push(countryNode);
        }
      });
      return tree;
    },
    filteredDealersBySection() {
      return this.showZeroValues
          ? this.dealersBySection
          : this.dealersBySection.filter(dealer => dealer.SUMM_PRICE !== 0);
    },
  },
  mounted() {
    this.parseUrlParams();
    this.getCountryRegionCityDealers();
    this.getDealersBySection().then(() => {
      // После получения всех данных и формирования formattedTree, вызываем метод для установки начального поиска
      this.filteredSearchSection();
    });
    this.parseUrlParams();
  },
  watch: {
    // Отслеживаем изменения поисковой строки
    search(newValue) {
      // Проверяем, пуста ли строка поиска
      if (!newValue && this.selectedItemId) {
        // Если поисковая строка пуста и был выбран sectionId, сбрасываем выбор
        this.selectedItemId = null;
        // Обновляем URL, удаляя параметр sectionId
        this.updateUrlParams();
        this.queryBySearchParams(); // Обновляем URL
        this.getDealersBySection();
      }
    }
  },
  methods: {
    async getDealersBySection() {
      this.queryBySearchParams();

      let query = '&sectionId='+this.selectedItemId
      this.selectedProducts.forEach((string) => {
        if (!string) return false;

        query += '&products[]='+string
      })

      try {
        this.isLoadingTable = true;
        const response = await Request.get(
            process.env.VUE_APP_LOCAL_SERVER + `/bitrix/services/main/ajax.php?action=ad:dealersnomenclatures.api.DealersNomenclaturesController.getDealerBySection${query}`
            // `http://localhost/bitrix/services/main/ajax.php?action=ad:dealersnomenclatures.api.DealersNomenclaturesController.getDealerBySection${query}`
        );
        this.dealersBySection = response.data;
        this.isLoadingTable = false;
      } catch (error) {
        console.error("Ошибка при загрузке данных о дилерах по городу:", error);
        this.isLoadingTable = false;
      }
    },
    async getCountryRegionCityDealers() {
      try {
        const response = await Request.get(
            process.env.VUE_APP_LOCAL_SERVER + `/bitrix/services/main/ajax.php?action=ad:dealersnomenclatures.api.DealersNomenclaturesController.countryRegionCitiesDealers`
            // `http://localhost/bitrix/services/main/ajax.php?action=ad:dealersnomenclatures.api.DealersNomenclaturesController.countryRegionCitiesDealers`
        );
        this.countryDealers = response.data;
      } catch (error) {
        console.error("Ошибка при загрузке данных о дилерах по городу:", error);
      }
    },
    async getDealerProducts(dealerId) {
      let query = '&dealerId='+dealerId
      this.selectedProducts.forEach((string) => {
        query += '&products[]='+string
      })

      try {
        const response = await Request.get(
            process.env.VUE_APP_LOCAL_SERVER + `/bitrix/services/main/ajax.php?action=ad%3Adealersnomenclatures.api.DealersNomenclaturesController.getNomenclaturesDealersNameQuantity${query}`
            // `http://localhost/bitrix/services/main/ajax.php?action=ad%3Adealersnomenclatures.api.DealersNomenclaturesController.getNomenclaturesDealersNameQuantity${query}`
        );
        this.dealerProducts = response.data;
      } catch (error) {
        console.error("Ошибка при загрузке данных о дилерах по городу:", error);
      }
    },
    clickHere(idSection) {
      this.selectedItemId = idSection;
      this.filteredSearchSection();
      this.getDealersBySection();
    },
    getTotalSummPrice() {
      let total = 0;
      for (let dealerGroup of this.dealersBySection) {
        total += dealerGroup.SUMM_PRICE;
      }
      return total;
    },
    updateUrlParams() {
      let query = { ...this.$route.query };
      if (this.selectedItemId) {
        // Если есть selectedItemId, добавляем его в URL
        query.sectionId = this.selectedItemId;
      } else {
        // Если selectedItemId нет, удаляем sectionId из URL
        delete query.sectionId;
      }
      // Обновляем URL с новыми параметрами
      this.$router.replace({ query }).catch(err => {});
    },
    parseUrlParams() {
      // Анализируем параметры URL и устанавливаем selectedItemId, если он есть
      const section = this.$route.query.sectionId;
      if (section) {
        this.selectedItemId = section;
      } else {
        this.selectedItemId = null;
      }

      const products = this.$route.query.product;
      if (products) {
        // Если параметр product является массивом, напрямую присваиваем его selectedProducts
        // Если нет, оборачиваем значение в массив, чтобы сохранить единообразие
        this.selectedProducts = Array.isArray(products) ? products : [products];
      } else {
        this.selectedProducts = []; // Сбрасываем, если в URL нет product
      }
      // Сразу вызываем updateUrlParams для синхронизации состояния с URL
      this.updateUrlParams();
    },
    queryBySearchParams() {
      let query = {};
      if (this.selectedItemId){
        query['sectionId'] = this.selectedItemId
      }

      if (this.selectedProducts){
        query['product'] = this.selectedProducts
      }

      this.$router.replace({
        query: query
      }).catch(()=>{});

    },
    filteredSearchSection() {
      this.formattedTree.forEach((item1) => {
        if (item1.id === this.selectedItemId) this.search=item1.name
        item1.children.forEach((item2) => {
          if (item2.id === this.selectedItemId) this.search=item2.name
          item2.children.forEach((item3) => {
            if (item3.id === this.selectedItemId) this.search=item3.name
          })
        })
      })
    },
  },
};

</script>

<style lang="scss">
.customTreeViewDNQ {
  .v-treeview-node__label {
    text-align: left;
  }
}

</style>

<style lang="scss" scoped>
@import "../assets/sass/main";

.dealers-container {
  max-width: 1200px;
  width: 1200px;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  left: -120px;
  position: absolute;
}

/* Стили для обертки данных, включая древовидную структуру и информацию о дилерах */
.dealers-container .wrapper_data {
  display: flex;
  gap: 20px; /* Отступ между контейнерами */
}

.dealers-container .wrapper_data .tree-wrapper {
  width: 330px; /* Минимальная ширина для дерева */
}

.dealers-container .wrapper_data .dealers-wrapper {
  width: 770px; /* Минимальная ширина для информации о дилерах */
}

/* Универсальные стили для элементов внутри .dealers-container */
.dealers-container .search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dealers-container .customCombobox {
  width: 50%;
  margin-left: 10px;
}

/* Стилизация групп дилеров и таблиц */
.dealers-container .dealer-group {
  margin-bottom: 20px;
}

.dealers-container .dealer-group h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.dealers-container .dealer-info table {
  width: 100%;
  border-collapse: collapse;
}

.dealers-container .dealer-info th, .dealer-info td {
  padding: 8px;
  border: 0px solid #333;
}

/*#ffbe983d*/


.dealers-container .dealer-info th {
  background-color: #f2f2f2;
  color: #333;
}

.dealers-container .dealer-info td {
  text-align: center;
}

.dealers-container .name-dealer a, {
  margin-right: 10px;
}

.dealers-container .name-dealer .name-menu-container, .name-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Стили для отображения информации и декоративных элементов */
.dealers-container .dealer-count-mark {
  background-color: #e0e0e0;
  border-radius: 4px;
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 80%;
}

.dealers-container .total-label,
.dealers-container .total-cell {
  text-align: right;
  font-weight: bold;
  background-color: #6aaaff17;
}

.dealers-container .total-label { width: 478px; }
.dealers-container .total-cell { width: 169px; }

.dealers-container .external-link {
  text-decoration: none;
}

.dealers-container .external-link:hover {
  text-decoration: underline;
}

.dealers-container .button-right-align {
  text-align: right;
}

.dealers-container .inner-table {
  width: 700px;
}

.dealers-container tfoot {
  background-color: #f2f2f2;
  position: sticky;
}

.dealers-container .collapsed {
  display: none;
}

</style>