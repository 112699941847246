import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import List_purchase_order from '../views/List_purchase_order.vue'
import receipt_overhead from '../views/receipt_overhead.vue'
import Shortage from '../views/Shortage.vue'
import Desktop from '../views/Desktop.vue'
import Contractors from '../views/Contractors'
import ListProductOnStore from '../components/ListProductOnStore.vue'
import Nomenclature from '../components/nomenclature/Nomenclature.vue'
import ListRemains from '../components/ListRemains.vue'
import QrCodeViewer from '../components/QrCodeViewer.vue'
import LayoutNomenclature from '../components/nomenclature/LayoutNomenclature.vue'
import LayoutSideBoxNomenclature from '../components/nomenclature/LayoutSideBoxNomenclature.vue'
import LayoutBarCode from '../components/nomenclature/LayoutBarCode.vue'
import TreeVirtualSpecification from '../components/specification/TreeVirtualSpecification.vue'
import Login from '../components/Login.vue'
import NomenclatureList from '../views/NomenclatureList'
import Specification from '../views/Specification'
import TestAuthApi from '../components/TestAuthApi'
import SpecificationCreate from '../views/specification/SpecificationCreate'
import SpecificationEdit from '../views/specification/SpecificationEdit'
import SpecificationView from '../views/specification/SpecificationView'
import SpecificationSearch from '@/views/specification/SpecificationSearch'
import DealersNomenclaturesQuantity from '../components/DealersNomenclaturesQuantity.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/test-auth-api/',
    name: 'TestAuthApi',
    component: TestAuthApi,
    meta: {
      requiresAuth: false,
    },
    props: {
      visible_front: true,
      ru_name: 'TestAuthApi',
      id: 'fixed_tab_TestAuthApi',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/',
    name: 'Desktop',
    component: Desktop,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Рабочий стол',
      id: 'fixed_tab_list_desktop',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/zakaz-naryady/',
    name: 'List_purchase_order',
    component: List_purchase_order,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: false,
      ru_name: 'Заказ-наряды',
      id: 'fixed_tab_list_po',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/spisok-nomenklatury/',
    name: 'NomenclatureList',
    component: NomenclatureList,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Номенклатура',
      id: 'fixed_tab_nomenclature',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/nomenklatura/',
    name: 'Nomenclature',
    component: Nomenclature,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Детальная номенклатура',
      id: 'fixed_tab_detail_nomenclature',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/specification',
    name: 'Specification',
    component: Specification,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Спецификация',
      id: 'fixed_tab_specification',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/specification/create',
    name: 'SpecificationCreate',
    component: SpecificationCreate,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Спецификация / Создание',
      // id: "fixed_tab_specification_create",
      check_access_cur_user: 'Y',
    },
  },
  {
    path: '/specification/edit/:id',
    name: 'SpecificationEdit',
    component: SpecificationEdit,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Спецификация / Редактирование',
      // id: "fixed_tab_specification_edit",
      check_access_cur_user: 'Y',
    },
  },
  {
    path: '/specification/view/:id?',
    name: 'SpecificationView',
    component: SpecificationView,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Спецификация / Просмотр',
      // id: "fixed_tab_specification_view",
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/specification/search',
    name: 'SpecificationSearch',
    component: SpecificationSearch,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Спецификация / Поиск',
      // id: "fixed_tab_specification_search",
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/prihody/',
    name: 'Receipt_overhead',
    component: receipt_overhead,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Приходы',
      id: 'fixed_tab_receipt_overhead',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/nedostacha/',
    name: 'Shortage',
    component: Shortage,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Недостача',
      id: 'fixed_tab_shortage',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/ostatki/',
    name: 'ListRemains',
    component: ListRemains,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Остатки',
      id: 'fixed_tab_remains',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/tps/',
    name: 'DealersNomenclaturesQuantity',
    component: DealersNomenclaturesQuantity,
    meta: {
      requiresAuth: false,
    },
    props: {
      visible_front: true,
      ru_name: 'ТПС',
      id: 'fixed_tab_nomenclaturesquantity',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/inventarizaciya/',
    name: 'ListProductOnStore',
    component: ListProductOnStore,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Инвентаризация',
      id: 'fixed_tab_list_product',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/contractors/',
    name: 'Contractors',
    component: Contractors,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: true,
      ru_name: 'Контрагенты',
      id: 'fixed_tab_contractors',
      check_access_cur_user: 'N',
    },
  },
  {
    path: '/qr_code/',
    name: 'QrCodeViewer',
    component: QrCodeViewer,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: false,
      ru_name: 'qr код',
    },
  },
  {
    path: '/layout_nomenclature/:id_prod?',
    name: 'LayoutNomenclature',
    component: LayoutNomenclature,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: false,
      ru_name: 'Этикетка номенклатуры',
    },
  },
  {
    path: '/layout_side_box_nomenclature/:id_prod?',
    name: 'LayoutSideBoxNomenclature',
    component: LayoutSideBoxNomenclature,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: false,
      ru_name: 'Этикетка боковая номенклатуры',
    },
  },
  {
    path: '/layout_bar_code/:id_prod?',
    name: 'LayoutBarCode',
    component: LayoutBarCode,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: false,
      ru_name: 'Штрихкод номенклатуры',
    },
  },
  {
    path: '/tree-specification/:id_v_prod?',
    name: 'TreeVirtualSpecification',
    component: TreeVirtualSpecification,
    meta: {
      requiresAuth: true,
    },
    props: {
      visible_front: false,
      ru_name: 'Виртуальное дерево спецификаций',
    },
  },
  {
    path: '/login/',
    name: 'login',
    component: Login,
    props: {
      visible_front: false,
      ru_name: 'Авторизация',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch('SetAccessUserGroup').then(() => {
      if (!!store.state.data_access_user_group_user.AUTH_USER_DATA.length) {
        next()
      } else {
        next('/login')
      }
    })
  } else {
    next()
  }

  /* Очищаем newElement при изменении урлов, но это происходит до инициализации (mounted) конкретного компонента,
		поэтому после очистки загружается там где должно загружаться
	*/
  if (to.path.match('specification')) {
    await store.dispatch('specificationStore/clearFormNomenclature')
  }
})

export default router
