var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dealers-container"},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1200"}},[_c('v-card-text',{staticClass:"wrapper_data"},[_c('div',{staticClass:"tree-wrapper"},[_c('v-text-field',{attrs:{"label":"Поиск","dense":"","hide-details":"","clearable":"","outlined":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-treeview',{staticClass:"customTreeViewDNQ",attrs:{"items":_vm.formattedTree,"search":_vm.search,"item-key":"name","open":_vm.open},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(!item.isDealer)?_c('span',[_c('a',{on:{"click":function($event){return _vm.clickHere(item.id)}}},[_c('span',[_vm._v(_vm._s(item.name))]),(item.count !== undefined)?_c('span',{staticClass:"dealer-count-mark"},[_vm._v(_vm._s(item.count))]):_vm._e()])]):_c('span',[_c('a',{staticClass:"external-link",attrs:{"href":'https://gidruss.ru/company/dealers/' + item.code + '/',"target":"_blank"}},[_c('TooltipStyled',{attrs:{"title":'Открыть карточку дилера'}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}}])})],1),_c('div',{staticClass:"dealers-wrapper"},[_c('div',{staticClass:"dealer-info"},[_c('div',{staticClass:"search-wrapper"},[_c('v-switch',{staticClass:"ma-2 customSwitch",attrs:{"hide-details":"","label":"Отобразить дилеров без наличия"},model:{value:(_vm.showZeroValues),callback:function ($$v) {_vm.showZeroValues=$$v},expression:"showZeroValues"}}),_c('v-combobox',{staticClass:"customCombobox",attrs:{"dense":"","clearable":"","multiple":"","small-chips":"","outlined":"","hide-details":"","deletable-chips":"","label":"Выбрать товары","placeholder":"Введите название товара"},on:{"change":function($event){return _vm.getDealersBySection()}},model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1),(_vm.$store.getters.getStateByGroupId(1))?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredDealersBySection,"loading":_vm.isLoadingTable},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-divider')]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.showZeroValues || item.SUMM_PRICE !== 0)?_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"name-dealer"},[_c('div',{staticClass:"name-menu-container"},[_c('a',{attrs:{"href":item.URL_DEALERS,"target":"_blank"}},[_c('TooltipStyled',{attrs:{"title":'Открыть карточку дилера'}},[_vm._v(" "+_vm._s(item.NAME)+" ")])],1)])]),_c('td',[_vm._v(_vm._s(item.COUNT)+" "),_c('v-menu',{attrs:{"offset-y":"","nudge-left":240},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"button-right-align",attrs:{"icon":""},on:{"click":function($event){return _vm.getDealerProducts(item.ID)}}},on),[_c('TooltipStyled',{attrs:{"title":'Посмотреть остатки с учётом выбранных товаров'}},[_c('v-icon',[_vm._v("mdi-calendar-text")])],1)],1)]}}],null,true)},[_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card-text',[_c('v-data-table',{staticClass:"inner-table",attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.dealerProducts,"item-key":"PRODUCT_ID"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.PRODUCT_NAME))]),_c('td',[_vm._v(_vm._s(item.QUANTITY_VAL !== null ? parseInt(item.QUANTITY_VAL) : 0))])])]}}],null,true)})],1)],1)],1)],1),_c('td',[_vm._v(_vm._s(item.SUMM_PRICE)+" ₽")]),_c('td',[_c('a',{attrs:{"href":item.ADMIN_URL,"target":"_blank"}},[_c('TooltipStyled',{attrs:{"title":'Редактировать карточку дилера'}},[_c('v-icon',[_vm._v("mdi-pencil-circle-outline")])],1)],1)])]):_vm._e()]}},{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"total-label"},[_vm._v("Всего в закупе:")]),_c('td',{staticClass:"total-cell"},[_vm._v(_vm._s(_vm.getTotalSummPrice())+" ₽")])])]},proxy:true}],null,false,2397119939)}):_vm._e()],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }