import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import Request from "../services/request"
import router from '../router'

import specificationStore from './modules/specificationStore'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		status: '',
		token: sessionStorage.getItem('token') || '',
		login: sessionStorage.getItem('login') || '',
		userFio: sessionStorage.getItem('userFio') || '',
		sessid: '',
		error_msg: '',
		// VUE_DEV_LOCAL_SERVER: 'https://dev.gidruss.ru',
		VUE_DEV_LOCAL_SERVER: process.env.VUE_APP_LOCAL_SERVER
			? process.env.VUE_APP_LOCAL_SERVER
			: '',
		VUE_TAB_PATH_PO: '',
		custom_alert: {
			data_alert: '',
			state_alert: false,
			type_alert: 'success',
		},
		system_notification_dialog: {
			state_dialog: false,
			body_message: '',
			body_sub_message: '',
			header_message: '',
			state_server_response: false,
			color: '',
			http_response: '',
			shouldUpdatePage: false,
		},
		/* Footer */
		data_new_order: {
			state_v_show: false,
			nomenclature_obj: null,
		},
		data_take_product_on_store: {
			state_v_show: false,
			nomenclature_obj: null,
			counter_call: 0,
		},
		performer_for_order: {
			id_order: null,
		},
		state_block_filters: true,
		data_production: {},
		data_nomenclature: [],
		list_type_products: [],
		data_nomenclature_from_plus: {},
		loading_nomenclature: false,
		data_nomenclature_key_value: {},
		data_access_user_group_user: {},
		data_global_variables: {},
		state_progress_circular_page: false,
		modal_detail_order: {
			state: false,
			order: null,
		},
		nomenclature_data: {
			arr_data_order: [],
		},
		printing_file: false,
		purchase_order: {
			list_purchase_order: [],
			count_data: {
				NUM_PAGE: 1,
			},
		},
		products_on_store: {
			list_products_on_store: [],
			count_data: {
				NUM_PAGE: 1,
			},
			data_filters: {
				stage_of_production: {
					ID: '1',
					DESCRIPTION: 'Пром',
					CODE: 'sklad_prom',
				},
			},
		},
		remains_on_store: {
			list_remains_on_store: [],
			count_data: {
				NUM_PAGE: 1,
			},
			data_filters: {
				name_product: '',
			},
		},
		debounceTimeout: null,
		desktop_action: null,
		smallSize: true,
		selected_type_or_subtype_products: {},
		shouldUpdateUrl: true,
		contractorsNomenclature: {
			list_contractors: [],
			count_data: {
				NUM_PAGE: 1,
			},
		},
		listNomenclatureByContractors: {
			list_items: [],
			count_data: {
				NUM_PAGE: 1,
			},
			data_filters: {
				name_product: '',
			},
			loading_input: false,
		},
		modalNomenclature: {
			state: false,
			company: null,
			totalCard: [],
		},
		listTypeContractors: [],
		prefixExist: false,
		prefixValue: '',
		prefixMessage: '',
		NomenclatureModal: null,
		tabContractor: '',
		dialog_nomenclature_window: false,
		/* DetailNomenclature */
		isLoading: false,
		// Поставщик по умолчанию
		contractorByDefault: {},
		/* NomenclatureList */
		dataFromUrl: {
			id_prod: {},
			subtype_prod: {},
		},
		shouldSendRequest: true,
		live_search_nomenclature: '',
		simpleModal: {
			state: false,
			message: '',
			state_accept_event: false,
			data_obj: {},
			component_name: '',
		},
		globalMessages: {
			message_kopirovanie_v_bufer:
				'Заявка скопирована в буфер обмена, откройте почту, вставьте текст и отправьте поставщику.',
		},
		/* SPECIFICATION */
		delete_uploaded: {},
		show_all_childs: false,
		edit_fields: false,
		alerts: [
			// {time: "18:04", text: 'Информация', type: $store.state.alert_type.warning}
		],
		alert_type: {
			success: 'success',
			info: 'info',
			warning: 'warning',
			error: 'error',
		},
	},
	getters: {
		isLoggedIn: (state) => !!state.token,
		authStatus: (state) => state.status,
		tokenGetter: (state) => state.token,
		userGetter: (state) => state.data_access_user_group_user.AUTH_USER_DATA,
		userFioGetter: (state) => (userData) => {
			const { FIRST_NAME, LAST_NAME } = userData
			return FIRST_NAME + ' ' + LAST_NAME
		},
		getObjectMessageColorForCode: (state) => (code) => {
			return state.data_global_variables.DATA_MESSAGE_NOTIFICATION.filter(
				(elem) => elem.code === code,
			)[0]
		},
		getDataDeniedFunctional: (state) => state.data_access_user_group_user,
		getNameFilteredSklad: (state) => {
			return state.products_on_store.data_filters.stage_of_production
				.DESCRIPTION
		},
		valid_system_message: (state) => {
			let formatted_string
			if (state.system_notification_dialog.body_message) {
				if (Array.isArray(state.system_notification_dialog.body_message)) {
					state.system_notification_dialog.body_message.forEach(function (
						elem,
					) {
						if (Array.isArray(elem)) {
							elem.forEach((elem2) => (formatted_string += elem2))
						}
					})
				} else {
					formatted_string = state.system_notification_dialog.body_message
				}
			} else {
				formatted_string = 'Ошибка. Обратитесь к администратору.'
			}
			return formatted_string
		},
		cutSymbols: (state) => (text) => {
			text = text.trim()
			const limit = screen.width >= 768 ? 30 : 13

			if (text.length <= limit) return text + '...'
			text = text.slice(0, limit) // тупо отрезать по лимиту

			// const lastSpace = text.lastIndexOf(" ");
			// if( lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
			//     text = text.substr(0, lastSpace);
			// }
			return text + '...'
		},
		getCurrentRouteData: (state) => (nameRoute) => {
			return router.options.routes.filter((elem) => elem.name === nameRoute)[0]
		},
		compareSubstringInString: (state) => (string, subString) => {
			const stringLower = string.toLowerCase()
			const subStringLower = subString.toLowerCase()
			// const compareBool = (stringLower.indexOf(subStringLower) !== -1);
			const compareBool = stringLower === subStringLower
			// console.log(stringLower);
			// console.log(subStringLower);
			// console.log(compareBool);
			return compareBool
		},
		checkStringForEmpty: (state) => (str) => {
			console.log('str')
			console.log(str)
			if (str != null && typeof str !== 'undefined') {
				str = str.trim()
			}
			return !str
		},
		getColorBorder: (state) => (color) => {
			return color + '_border'
		},
		getModalNomenclatureData: (state) => state.modalNomenclature,
		getToday: (state) => {
			const today = new Date()
			const dd = String(today.getDate())
			const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
			const yyyy = today.getFullYear()
			return dd + '.' + mm + '.' + yyyy
		},
		getStateByGroupId: (state, getters) => (idGroup) => {
			if (! getters.userGetter) return false

			return getters.userGetter.some((item) => item.GROUP_ID == idGroup)
		},
	},
	actions: {
        login({state, commit, dispatch}, user) {
            return new Promise(async (resolve, reject) => {
                commit('auth_request');
                console.log(user);
				// console.log(data.get('login_user'));
				/* На проде подставляется переменная чтобы с localhost ходил в нужную директорию */
				// console.log(typeof process.env.VUE_APP_LOCAL_SERVER !== 'undefined');
				// const param_local_server = (typeof process.env.VUE_APP_LOCAL_SERVER !== 'undefined') ? process.env.VUE_APP_LOCAL_SERVER : '';

                await Request.post(state.VUE_DEV_LOCAL_SERVER + '/local/BackendApi/PurchaseOrder/auth.php', user)
                    .then(resp => {
                        if (resp.status === 401) {
							// The request was made and the server responded with a status code
							// that falls out of the range of 2xx
                            console.log(resp.error_msg);
							// console.log(error.response.status);
							// console.log(error.response.headers);
                            commit('auth_error', {'error_msg': (resp.error_msg.MESSAGE)});
                            sessionStorage.removeItem('token');
                            sessionStorage.removeItem('login');
                            reject(resp);
                        }

                        const token = resp.token;
                        const login = resp.login;
                        const sessid = resp.sessid;
                        const userFio = this.getters.userFioGetter(resp.SESSION.SESS_AUTH);
                        sessionStorage.setItem('token', token);
                        sessionStorage.setItem('login', login);
                        sessionStorage.setItem('userFio', userFio);
                        axios.defaults.headers.common['Authorization'] = token;
                        commit('auth_success', {
                            'token': token,
                            'userFio': userFio,
                            'login': login,
                            'sessid': sessid
                        });
                        resolve(resp);
                    })

                await dispatch('SetAccessUserGroup');


				// axios({url: '/local/BackendApi/PurchaseOrder/auth.php', data: user, method: 'post'})
				//     .then(resp => {
				//         console.log(resp);
				//         const token = resp.data.token;
				//         const user = resp.data.user;
				//         localStorage.setItem('token', token);
				//         axios.defaults.headers.common['Authorization'] = token;
				//         commit('auth_success', {'token': token, 'user': user} );
				//         resolve(resp);
				//     })
				//     .catch(err => {
				//         commit('auth_error');
				//         localStorage.removeItem('token');
				//         reject(err);
				//     })
			})
		},
		async logout({ state, commit }) {
			return new Promise(async (resolve, reject) => {
				await Request.post(state.VUE_DEV_LOCAL_SERVER + '/local/BackendApi/PurchaseOrder/Logout.php', {logout: 'true'})
				commit('logout')
				sessionStorage.removeItem('token')
				sessionStorage.removeItem('login')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		},
        async SetAccessUserGroup({state, commit}) {
            const response = await Request.post(
                Request.bitrixUrl('nk:production.list_purchase_order',
                    'executeGetDataAccessUserGroupUser')
            )
            // console.log('response SetAccessUserGroup')
            // console.log(response)
            if (response.status === "success") {
                // Если форма успешно отправилась
                console.log(response.data);
                commit('set_data_access_user_group_user', response.data);
                // _this.$store.state.data_production = response.data;
            }

            return response;
            // .then(function (response) {
            //     console.log('response SetAccessUserGroup')
            //     console.log(response)
            //         if (response.status === "success") {
            //             // Если форма успешно отправилась
            //             console.log(response.data);
            //             commit('set_data_access_user_group_user', response.data);
            //             resolve();
            //             // _this.$store.state.data_production = response.data;
            //         }
            //     })
            // .catch(response => {
            //     console.log(response);
            // });
		},
		SetGlobalVariables({ state, commit }) {
			const _this = this
			BX.ajax
				.runComponentAction(
					'nk:production.list_purchase_order',
					'executeGetDataGlobalVariables',
					{
						// Вызывается без постфикса Action
						mode: 'class',
						data: {
							login: _this.state.login,
							token: _this.state.token,
						},
					},
				)
				.then(function (response) {
					if (response.status === 'success') {
						commit('set_global_variables', response.data)
					}
				})
				.catch((response) => {
					console.log(response)
				})
		},
		SetDataProduction({ state, commit }) {
			return new Promise((resolve, reject) => {
				const _this = this

				/* Битриксовый AJAX */
				BX.ajax
					.runComponentAction(
						'nk:production.list_purchase_order',
						'executeGetDataProduct',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: { 1: '1' },
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.status === 'success') {
							// Если форма успешно отправилась
							console.log(response.data)
							response.data.STATE_ORDER_ARR = response.data.STATE_ORDER_ARR.map(
								function (elem, key) {
									return { id: key, text: elem }
								},
							)

							commit('set_data_production', response.data)
							console.log('dispatch SetDataProduction')
							resolve()
						}
					})
					.catch((response) => {
						console.log(response)
						resolve()
					})
			})
		},
		SetListPurcahseOrder({ commit }, response) {
			commit('set_list_purchase_order', response)
		},
		// set_alert_data(context) {
		//     console.log(context);
		//     context.commit('set_alert_data', context);
		// },
		ClearCustomAlert({ commit }) {
			commit('clear_custom_alert')
		},
		setCounterTakeToStore({ commit }) {
			commit('set_counter_take_to_store')
		},
		getListRemains({ commit }, id_prod = null) {
			const _this = this
			_this.state.state_progress_circular_page = true

			BX.ajax
				.runComponentAction(
					'nk:production.list_remains_on_store',
					'executeGetListRemainsOnStore',
					{
						mode: 'class',
						data: {
							post: {
								name_product:
									_this.state.remains_on_store.data_filters.name_product,
								num_page: _this.state.remains_on_store.count_data.NUM_PAGE,
							},
							login: _this.state.login,
							token: _this.state.token,
						},
					},
				)
				.then(function (response) {
					console.log('getListRemains')
					console.log(response)
					commit('set_remains_on_store', response)
				})
				.catch((response) => {
					console.log(response)
					commit('set_remains_on_store', response)
				})
		},
		getListProducts({ commit }) {
			console.log('GetListProd')
			const _this = this
			_this.state.state_progress_circular_page = true

			BX.ajax
				.runComponentAction(
					'nk:production.list_product_on_store',
					'executeGetListProductOnStore',
					{
						mode: 'class',
						data: {
							post: {
								id_store: !!_this.state.products_on_store.data_filters
									.stage_of_production
									? _this.state.products_on_store.data_filters
											.stage_of_production.ID
									: null,
								num_page: _this.state.products_on_store.count_data.NUM_PAGE,
								selected_type_or_subtype_products: !!_this.state
									.selected_type_or_subtype_products
									? _this.state.selected_type_or_subtype_products
									: null,
							},
							login: _this.state.login,
							token: _this.state.token,
						},
					},
				)
				.then(function (response) {
					console.log('executeGetListProductOnStore')
					console.log(response)
					commit('set_products_on_store', response)
				})
				.catch((response) => {
					console.log(response)
					commit('set_products_on_store', response)
				})
		},
		getNomenclatureById({ commit }, id_prod) {
			return new Promise((resolve, reject) => {
				const _this = this
				_this.state.loading_nomenclature = true

				BX.ajax
					.runComponentAction(
						'nk:production.list_nomenclature',
						'executeGetListNomenclature',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: {
									num_page: 1,
									id_prod: !!id_prod ? id_prod : '',
								},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.data.state) {
							commit('add_data_nomenclature', response.data.log_data.DATA[0])
						}
						resolve(response.data.log_data)
						_this.state.loading_nomenclature = false
					})
					.catch((response) => {
						console.log(response)
						_this.state.loading_nomenclature = false
						commit('set_system_notification_result', response)
						reject(response)
					})
			})
		},
		setNomenclatureByNameOrArticul({ commit }, payload) {
			console.log('setNomenclatureByNameOrArticul')
			const _this = this
			const { nameOrArticul, filterByType, selectedTypeProducts } = payload

			if (!!selectedTypeProducts && Object.keys(selectedTypeProducts).length)
				return false

			if (!nameOrArticul) return false

			const w = this.state.data_nomenclature.some((elem) =>
				this.getters.compareSubstringInString(elem.text, nameOrArticul),
			)
			if (w) return false

			if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout)
			this.state.debounceTimeout = setTimeout(() => {
				console.log('setNomenclatureByNameOrArticul')
				_this.state.loading_nomenclature = true

				BX.ajax
					.runComponentAction(
						'nk:production.list_purchase_order',
						'executeDataNomenclatureByNameOrArticul',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: {
									nameOrArticul: !!nameOrArticul ? nameOrArticul : '',
									filterByType: !!filterByType ? filterByType : '',
								},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.data.state) {
							console.log('setNomenclatureByNameOrArticul')
							console.log(response.data)
							/* Cортируем продукцию по полю ID_CATEGORY */
							response.data.log_data.sort(
								(prev, next) => prev.ID_CATEGORY - next.ID_CATEGORY,
							)

							commit('set_data_nomenclature', response.data.log_data)
						}
						_this.state.loading_nomenclature = false
					})
					.catch((response) => {
						console.log(response)
						_this.state.loading_nomenclature = false
					})
			}, 1000)
		},
		setListProductsByTypeAction({ commit }) {
			const _this = this

			_this.state.loading_nomenclature = true

			/* Битриксовый AJAX */
			BX.ajax
				.runComponentAction(
					'nk:production.add_purchase_order',
					'executeGetListProductsByType',
					{
						// Вызывается без постфикса Action
						mode: 'class',
						data: {
							post: {
								selected_type_or_subtype_products: !!_this.state
									.selected_type_or_subtype_products
									? _this.state.selected_type_or_subtype_products
									: '',
							},
							login: _this.state.login,
							token: _this.state.token,
						},
					},
				)
				.then(function (response) {
					if (response.status === 'success') {
						console.log('WOW setListProductsByTypeAction')
						console.log(response.data)
						if (
							response.data?.log_data?.selected_type_or_subtype_products === ''
						) {
							commit('set_data_nomenclature', [])
						} else {
							commit('set_data_nomenclature', response.data.log_data)
						}
					}
					_this.state.loading_nomenclature = false
				})
				.catch((response) => {
					console.log(response)
					_this.state.loading_nomenclature = false
					commit('set_system_notification_result', response)
				})
		},
		setListTypeProductsAction({ commit }) {
			return new Promise((resolve, reject) => {
				const _this = this

				if (_this.state.list_type_products.length) return false

				BX.ajax
					.runComponentAction(
						'nk:production.add_purchase_order',
						'executeGetListTypeProducts',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.status === 'success') {
							console.log(response)
							if (response.data.state) {
								console.log('getListTypeProductsAction')
								console.log(response.data.log_data)

								commit('set_list_type_products', response.data.log_data)
								_this.state.state_progress_circular_page = false
								resolve()
							}
						}
					})
					.catch((response) => {
						console.log(response)
						commit('set_system_notification_result', response)
						resolve()
					})
			})
		},

		setSystemNotificationResult({ commit }, server_response) {
			commit('set_system_notification_result', server_response)
		},
		getUpdatedURL({ commit }, object) {
			// const _this = this
			return new Promise((resolve, reject) => {
				commit('updateURl_desktop', object)
				resolve()
			})
		},
		setNomenclatureSendingStatus({ commit }, ID) {
			return new Promise((resolve, reject) => {
				commit('set_nomenclature_sending_status', ID)
				resolve()
			})
		},

		/* NomenclatureList */
		clearModalNomenclature({ commit }) {
			commit('clear_modal_nomenclature')
		},
		removeModalNomenclatureItem({ commit }, id) {
			commit('remove_modal_nomenclature_item', id)
		},
		setTotalCard({ commit }, object) {
			commit('set_total_card', object)
		},
		queryToFillDocumentWithNomenclatureBy1c({ commit }) {
			return new Promise((resolve) => {
				const _this = this
				_this.state.loading_nomenclature = true

				BX.ajax
					.runAction(
						'nk:odata.api-1c.documentscontroller.createDocumentWithNomenclature',
						{
							// Вызывается без постфикса Action
							data: {
								post: {
									array_nomenclature: _this.state.modalNomenclature.totalCard,
									company_data: _this.state.modalNomenclature.company,
								},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.data.state) {
							console.log('queryToFillDocumentWithNomenclatureBy1c')
						}
						commit('set_system_notification_result', response.data)
						resolve(response.data.log_data)
						_this.state.loading_nomenclature = false
					})
					.catch((response) => {
						console.log(response)
						_this.state.loading_nomenclature = false
						commit('set_system_notification_result', response)
					})
			})
		},
		setTotalSum({ commit }, object) {
			commit('set_total_sum', object)
		},
		/* */
		getListTypeContractors({ commit }) {
			return new Promise((resolve) => {
				const _this = this

				BX.ajax
					.runComponentAction(
						'nk:production.list_contractors',
						'executeGetListTypeContractors',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: {},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.status === 'success') {
							console.log(response)
							commit('set_types_contractors', response.data.log_data)
							resolve()
						}
					})
					.catch((response) => {
						console.log(response)
						commit('set_system_notification_result', response)
						resolve()
					})
			})
		},
		getListNomenclatureFoLiveSearch({ commit }, object) {
			return new Promise((resolve, reject) => {
				const _this = this

				_this.state.state_progress_circular_page = true

				const { name = null } = object

				if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout)
				this.state.debounceTimeout = setTimeout(() => {
					BX.ajax
						.runComponentAction(
							'nk:production.list_nomenclature',
							'executeGetListNomenclature',
							{
								// Вызывается без постфикса Action
								mode: 'class',
								data: {
									post: {
										num_page: 1,
										name: !!name ? name : '',
									},
									login: _this.state.login,
									token: _this.state.token,
								},
							},
						)
						.then(function (response) {
							if (response.status === 'success') {
								console.log(response)
								if (response.data.state) {
									if (
										response.data.log_data.DATA &&
										response.data.log_data.DATA.length
									) {
										commit('set_data_nomenclature', response.data.log_data.DATA)
									} else {
										commit('set_data_nomenclature', [])
									}

									_this.state.state_progress_circular_page = false
									resolve()
								}
							}
						})
						.catch((response) => {
							console.log(response)
							commit('set_system_notification_result', response)
							resolve()
						})
				}, 500)
			})
		},
		getListNomenclature({ commit }, object) {
			return new Promise((resolve, reject) => {
				const _this = this

				_this.state.state_progress_circular_page = true

				const {
					CUSTOM_CONTRACTOR_ID = null,
					name = null,
					id_prod = null,
					color = null,
					type_contractor = null,
					contractor_by_default = null,
					selected_type_or_subtype_products = null,
				} = object

				if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout)
				this.state.debounceTimeout = setTimeout(() => {
					BX.ajax
						.runComponentAction(
							'nk:production.list_nomenclature',
							'executeGetListNomenclature',
							{
								// Вызывается без постфикса Action
								mode: 'class',
								data: {
									post: {
										num_page: !!_this.state.listNomenclatureByContractors
											.count_data.NUM_PAGE
											? _this.state.listNomenclatureByContractors.count_data
													.NUM_PAGE
											: 1,
										id_contractor: !!CUSTOM_CONTRACTOR_ID
											? CUSTOM_CONTRACTOR_ID
											: '',
										name: !!name ? name : '',
										color: !!color ? color : '',
										id_prod: !!id_prod ? id_prod : '',
										type_contractor: !!type_contractor ? type_contractor : '',
										contractor_by_default: !!contractor_by_default
											? contractor_by_default
											: '',
										selected_type_or_subtype_products:
											!!selected_type_or_subtype_products
												? selected_type_or_subtype_products
												: '',
									},
									login: _this.state.login,
									token: _this.state.token,
								},
							},
						)
						.then(function (response) {
							if (response.status === 'success') {
								console.log(response)
								if (response.data.state) {
									console.log('getListNomenclature')

									if (
										_this.state.listNomenclatureByContractors.count_data
											.NUM_PAGE == 1
									) {
										commit('set_list_nomeclature', response.data.log_data)
									} else {
										console.log('num page')
										commit('add_list_nomenclature', response.data.log_data)
									}

									_this.state.state_progress_circular_page = false
									resolve()
								}
							}
						})
						.catch((response) => {
							console.log(response)
							commit('set_system_notification_result', response)
							resolve()
						})
				}, 500)
			})
		},
		setPropertyItemAnyIblock({ commit }, object) {
			return new Promise((resolve, reject) => {
				console.log('do setPropertyItemAnyIblock')
				const _this = this
				console.log(object)
				const { id_elem, code_prop, value_prop, iblock_id } = object
				_this.state.listNomenclatureByContractors.loading_input = true

				// if (this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod))
				//     return false;

				// if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout);
				// this.state.debounceTimeout = setTimeout(() => {

				BX.ajax
					.runComponentAction(
						'nk:production.detail_nomenclature',
						'executeSetPropertyItemAnyIblock',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: {
									id_elem: id_elem,
									code_prop: code_prop,
									value_prop: value_prop ? value_prop : '',
									iblock_id: !!iblock_id ? iblock_id : null,
								},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.status === 'success') {
							console.log(response)
							if (response.data.state) {
								_this.state.listNomenclatureByContractors.loading_input = false
								// commit('set_system_notification_result', response.data);
							} else {
								commit('set_system_notification_result', response.data)
							}
							resolve()
						}
					})
					.catch((response) => {
						console.log(response)
						commit('set_system_notification_result', response)
						_this.state.listNomenclatureByContractors.loading_input = false
						resolve()
					})

				// }, 500)
			})
		},
		setPropertyContractorsBitrixIBlock({ commit }, object) {
			return new Promise((resolve, reject) => {
				console.log('setPropertyContractorsBitrixIBlock')
				const _this = this
				const { id_elem, code_prop, value_prop } = object
				_this.state.listNomenclatureByContractors.loading_input = true

				BX.ajax
					.runComponentAction(
						'nk:production.detail_nomenclature',
						'executeSetPropertyContractorsBitrixBlock',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: {
									id_elem: id_elem,
									code_prop: code_prop,
									value_prop: value_prop ? value_prop : '',
								},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.status === 'success') {
							console.log(response)
							if (response.data.state) {
								_this.state.listNomenclatureByContractors.loading_input = false
							} else {
								commit('set_system_notification_result', response.data)
							}
							resolve()
						}
					})
					.catch((response) => {
						console.log(response)
						commit('set_system_notification_result', response)
						_this.state.listNomenclatureByContractors.loading_input = false
						resolve()
					})
			})
		},
		addToInternalArticlesTable({ commit }, object) {
			return new Promise((resolve, reject) => {
				console.log('do executeAddToInternalArticlesTable')
				const _this = this

				const { custom_contractor_id } = object

				// if (this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod))
				//     return false;

				if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout)
				this.state.debounceTimeout = setTimeout(() => {
					console.log('debounce internal Article')

					BX.ajax
						.runComponentAction(
							'nk:production.list_contractors',
							'executeAddToInternalArticlesTable',
							{
								// Вызывается без постфикса Action
								mode: 'class',
								data: {
									post: {
										custom_contractor_id: custom_contractor_id,
										// prefix: prefix,
									},
									login: _this.state.login,
									token: _this.state.token,
								},
							},
						)
						.then(function (response) {
							if (response.status === 'success') {
								console.log(response)
								console.log(response.data.message)

								if (response.data.state) {
									// commit('set_system_notification_result', response.data);
								} else {
									// commit('set_system_notification_result', response.data);
								}
								resolve()
							}
						})
						.catch((response) => {
							console.log(response)
							commit('set_system_notification_result', response)
							resolve()
						})
				}, 500)
			})
		},
		fillPropertyPrefixToContractorsTable({ commit }, object) {
			return new Promise((resolve, reject) => {
				console.log('do executefillPropertyPrefixToContractorsTable')
				const _this = this

				const { custom_contractor_id, prefix } = object

				console.log(custom_contractor_id)
				console.log(prefix)

				// if (this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod))
				//     return false;

				// if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout);
				// this.state.debounceTimeout = setTimeout(() => {

				console.log('debounce prefToTable')

				BX.ajax
					.runComponentAction(
						'nk:production.list_contractors',
						'executeFillPropertyPrefixToContractorsTable',
						{
							// Вызывается без постфикса Action
							mode: 'class',
							data: {
								post: {
									custom_contractor_id: custom_contractor_id,
									prefix: prefix,
								},
								login: _this.state.login,
								token: _this.state.token,
							},
						},
					)
					.then(function (response) {
						if (response.status === 'success') {
							console.log(response)
							console.log(response.data.message)
							if (
								response.data.message === 'Такой префикс уже существует' ||
								response.data.state === false
							) {
								_this.state.prefixExist = true
								_this.state.prefixValue = prefix
								_this.state.prefixMessage = response.data.message
							} else {
								_this.state.prefixExist = false
								_this.state.prefixValue = ''
							}
							if (response.data.state) {
								commit('set_system_notification_result', response.data)
							} else {
								commit('set_system_notification_result', response.data)
							}
							resolve()
						}
					})
					.catch((response) => {
						console.log(response)
						commit('set_system_notification_result', response)
						resolve()
					})

				// }, 500)
			})
		},
		setInternalArticle({ commit }, object) {
			return new Promise((resolve, reject) => {
				console.log('do setInternalArticle')
				const _this = this

				const { custom_contractor_id, prefix } = object

				// if (this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod) || this.getters.checkStringForEmpty(id_prod))
				//     return false;

				if (this.state.debounceTimeout) clearTimeout(this.state.debounceTimeout)
				this.state.debounceTimeout = setTimeout(() => {
					BX.ajax
						.runComponentAction(
							'nk:production.list_contractors',
							'executeSetInternalArticle',
							{
								// Вызывается без постфикса Action
								mode: 'class',
								data: {
									post: {
										custom_contractor_id: custom_contractor_id,
										// prefix: prefix,
									},
									login: _this.state.login,
									token: _this.state.token,
								},
							},
						)
						.then(function (response) {
							if (response.status === 'success') {
								console.log(response)
								console.log(response.data.message)
								if (
									response.data.message === 'Такой префикс уже существует' ||
									response.data.state === false
								) {
									_this.state.prefixExist = true
									_this.state.prefixValue = prefix
									_this.state.prefixMessage = response.data.message
								} else {
									_this.state.prefixExist = false
									_this.state.prefixValue = ''
								}
								if (response.data.state) {
									// commit('set_system_notification_result', response.data);
								} else {
									// commit('set_system_notification_result', response.data);
								}
								resolve()
							}
						})
						.catch((response) => {
							console.log(response)
							commit('set_system_notification_result', response)
							resolve()
						})
				}, 500)
			})
		},
		copyToClipboard({ commit, state }) {
			let obj_to_copy = ''
			obj_to_copy =
				'Email - ' + this.getters.getModalNomenclatureData.company.EMAIL
					? this.getters.getModalNomenclatureData.company.EMAIL
					: '' + '\r\n\n'
			obj_to_copy += '\n' + 'Заявка от ' + this.getters.getToday + '\r\n\n'

			if (!state.modalNomenclature.totalCard.length) return false

			let inc = 0
			state.modalNomenclature.totalCard.forEach(function (elem) {
				const quantity_to_order = !!elem.QUANTITY_TO_ORDER
					? elem.QUANTITY_TO_ORDER + ' шт.'
					: 'не указано кол-во'

				inc += 1
				obj_to_copy +=
					inc + ') ' + elem.NAME_PROD + ' - ' + quantity_to_order + '\r\n'
			})
			console.log('obj_to_copy')
			console.log(obj_to_copy)

			if (navigator.clipboard && window.isSecureContext) {
				// navigator clipboard api method'
				commit(
					'set_simple_modal',
					state.globalMessages.message_kopirovanie_v_bufer,
				)
				return navigator.clipboard.writeText(obj_to_copy)
			} else {
				// text area method
				let textArea = document.createElement('textarea')
				textArea.value = obj_to_copy
				// make the textarea out of viewport
				textArea.style.position = 'fixed'
				textArea.style.left = '-999999px'
				textArea.style.top = '-999999px'
				document.body.appendChild(textArea)
				textArea.focus()
				textArea.select()
				return new Promise((res, rej) => {
					document.execCommand('copy') ? res() : rej()
					commit(
						'set_simple_modal',
						state.globalMessages.message_kopirovanie_v_bufer,
					)
					textArea.remove()
				})
			}
		},
		addAlertSpecification({ commit, state }, alert_data) {
			console.log(alert_data)
			commit('set_new_alert_specification', alert_data)
		},
        async checkAuth({state, dispatch}){
            // if (process.env.NODE_ENV === 'development')
            //     return true;

            //production environment
            const response = await dispatch('SetAccessUserGroup');
            // console.log('response SetAccessUserGroup')
            // console.log(response)
            if (response.status === 'error'){
                console.log('OBRATNO')
                return false;
            }
            if (! response.data.AUTH_USER_DATA.length){
                return false;
            }
            return true;

            // if (!Object.keys(state.data_access_user_group_user).length) {
            //     if (!location.pathname.match('login')) {
            //         return false;
            //     }
            //     return false;
            // }
        },
		setSimpleModal({commit, state}, obj){
			commit('set_simple_modal', obj)
		},
		hideSimpleModal({commit}){
			commit('hide_simple_modal')
		},
	},
	mutations: {
		// Если с запроса возращается пустой массив, чтобы не сбрасывался селектор
		set_fake_list_nomenclature() {},
		set_list_nomeclature(state, object) {
			state.listNomenclatureByContractors.list_items = []
			state.listNomenclatureByContractors.list_items = object.DATA
			state.listNomenclatureByContractors.count_data = object.COUNT
			if (!state.modalNomenclature.totalCard.length) {
				state.listNomenclatureByContractors.list_items.map((elem) => {
					elem.QUANTITY_TO_ORDER = 0
				})
			} else {
				state.listNomenclatureByContractors.list_items.map((elem) => {
					if (state.modalNomenclature.totalCard.some((i) => i.ID === elem.ID)) {
						let index = state.modalNomenclature.totalCard.findIndex(
							(i) => i.ID === elem.ID,
						)
						if (index !== -1)
							elem.QUANTITY_TO_ORDER =
								state.modalNomenclature.totalCard[index].QUANTITY_TO_ORDER
					} else {
						elem.QUANTITY_TO_ORDER = 0
					}
				})
			}
		},
		add_list_nomenclature(state, object) {
			state.listNomenclatureByContractors.list_items.push(...object.DATA)
			state.listNomenclatureByContractors.count_data = object.COUNT
			if (!state.modalNomenclature.totalCard.length) {
				state.listNomenclatureByContractors.list_items.map((elem) => {
					elem.QUANTITY_TO_ORDER = 0
				})
			} else {
				state.listNomenclatureByContractors.list_items.map((elem) => {
					if (state.modalNomenclature.totalCard.some((i) => i.ID === elem.ID)) {
						let index = state.modalNomenclature.totalCard.findIndex(
							(i) => i.ID === elem.ID,
						)
						if (index !== -1)
							elem.QUANTITY_TO_ORDER =
								state.modalNomenclature.totalCard[index].QUANTITY_TO_ORDER
					} else {
						elem.QUANTITY_TO_ORDER = 0
					}
				})
			}
		},

		/* NomenclatureList */
		// Добавить в корзину
		set_total_card(state, object) {
			console.log('set_total_card')
			console.log(object)

			const arr = state.modalNomenclature.totalCard
			const findIndex = arr.findIndex((elem) => elem.ID === object.ID)

			if (object.QUANTITY_TO_ORDER <= 0) {
				arr.splice(findIndex, 1)
				return false
			}

			if (arr.length === 0) {
				arr.push(object)
				return false
			}
			if (findIndex === -1) arr.push(object)
			else arr[findIndex] = object
		},
		// Подсчитать сумму
		set_total_sum(state, object) {
			// FIXME
		},
		// Записать данные с бэка
		setModalNomenclature(state, payload) {
			state.modalNomenclature.state = payload.state
			state.modalNomenclature.company = payload.company
		},
		// Очистить полностью корзину
		clear_modal_nomenclature(state) {
			state.modalNomenclature.totalCard = []
			state.modalNomenclature.state = false
			state.modalNomenclature.company = null
		},
		// Удалить Элемент из корзины
		remove_modal_nomenclature_item(state, id) {
			let index = state.modalNomenclature.totalCard.findIndex((i) => {
				return i.ID === id
			})
			if (index !== -1) {
				state.modalNomenclature.totalCard.splice(index, 1)
				state.listNomenclatureByContractors.list_items.map((elem) => {
					if (elem.ID === id) {
						elem.QUANTITY_TO_ORDER = 0
					}
				})
			}
		},
		/* */

		set_nomenclature_sending_status(state, ID) {
			state.products_on_store.list_products_on_store.map((elem) => {
				if (elem.ID === ID) {
					elem.data_update_commentary =
						this.getters.getObjectMessageColorForCode('send')
				}
			})
		},
		setStateAddNewOrder(state, value) {
			state.data_new_order.state_v_show = value
		},
		// Для регулирования изменения URL при взаимодействии с рабочим столом
		changeShouldUpdateUrl(state, value) {
			state.shouldUpdateUrl = value
		},
		updateURl_desktop(state, object) {
			if (state.shouldUpdateUrl) {
				router
					.push({
						path: !!object.path ? object.path : '/',
						query: { ...object.querys },
					})
					.catch(() => {})
			} else {
				state.shouldUpdateUrl = true
			}
		},
		updateURL_components(state, object) {
			if (state.shouldUpdateUrl) {
				router
					.push({
						path: router.path,
						query: object,
					})
					.catch((failure) => {
						// console.log(failure.to.path)
						// console.log(failure.from.path)
					})
			} else {
				state.shouldUpdateUrl = true
			}
		},
		change_data_filter(state, obj) {
			state.data_filters.stage_of_production = obj
		},
		auth_request(state) {
			state.status = 'loading'
		},
		auth_success(state, obj) {
			console.log(state)
			console.log(obj)
			state.status = 'success'
			state.login = obj.login
			state.token = obj.token
			state.userFio = obj.userFio
			state.sessid = obj.sessid
		},
		auth_error(state, obj) {
			state.status = 'error'
			state.error_msg = obj.error_msg
		},
		logout(state) {
			state.status = ''
			state.token = ''
			state.login = ''
			state.data_production = {}
		},
		set_data_access_user_group_user(state, obj) {
			state.data_access_user_group_user = {}
			state.data_access_user_group_user = obj
		},
		set_global_variables(state, obj) {
			state.data_global_variables = {}
			state.data_global_variables = obj
		},
		add_data_nomenclature(state, obj) {
			const check_exist = state.data_nomenclature.some(
				(elem) => elem.ID === obj.ID,
			)
			if (!check_exist) state.data_nomenclature.push(obj)
		},
		set_data_nomenclature(state, obj) {
			state.data_nomenclature = []
			state.data_nomenclature = obj
		},
		set_list_type_products(state, arr) {
			state.list_type_products = []
			state.list_type_products = arr
		},
		set_data_production(state, obj) {
			state.data_production = {}
			state.data_production = obj
		},
		set_list_purchase_order(state, response) {
			if (response.status === 'success') {
				const { COUNT, LIST_ORDER } = response.data

				state.purchase_order.count_data = COUNT

				if (state.purchase_order.count_data.NUM_PAGE === 1) {
					state.purchase_order.list_purchase_order = LIST_ORDER
				} else {
					state.purchase_order.list_purchase_order.push(...LIST_ORDER)
				}
			}
		},
		// set_alert_data(state, obj){
		//     state.custom_alert.data_alert = obj.data_alert;
		//     state.custom_alert.state_alert = obj.state_alert;
		//     state.custom_alert.type_alert = obj.type_alert;
		// }
		clear_custom_alert(state) {
			state.custom_alert.state_alert = false
			state.custom_alert.data_alert = ''
		},
		set_counter_take_to_store(state, response) {
			state.data_take_product_on_store.counter_call++
		},
		set_products_on_store(state, response) {
			if (response.status === 'success') {
				const { COUNT, DATA } = response.data

				state.products_on_store.count_data = COUNT

				/* FIXME: Удалить заглушку, когда будет бэк */

				// function ConstructorProd(DATA, perProd) {
				//     this.AMOUNT = DATA.AMOUNT;
				//     this.ARTICUL = DATA.ARTICUL;
				//     this.CODE = DATA.CODE;
				//     this.DEFAULT_AMOUNT = DATA.DEFAULT_AMOUNT;
				//     this.DRAWING_NUMBER = DATA.DRAWING_NUMBER;
				//     this.HOW_MANY_PIECES_IN_PACKAGE = DATA.HOW_MANY_PIECES_IN_PACKAGE;
				//     this.ID = DATA.ID;
				//     this.MINIMUM_QUANTITY_GIDRUSS = DATA.MINIMUM_QUANTITY_GIDRUSS;
				//     this.NAME = DATA.NAME;
				//     this.PICTURE = DATA.PICTURE;
				//     this.PRODUCT_ID = DATA.PRODUCT_ID;
				//     this.STORE_ID = DATA.STORE_ID;
				//     this.STORE_NAME = DATA.STORE_NAME;
				//     this.SUBTYPE_TABLE_UF_XML_ID = DATA.SUBTYPE_TABLE_UF_XML_ID;
				//     this.TYPE_TABLE_UF_XML_ID = DATA.TYPE_TABLE_UF_XML_ID;
				//     this.perProd = perProd;
				//     this.total = 0;
				//     this.editable = false;
				//     this.counter = 0;
				// }
				// function generatePrice() {
				//     return Math.floor(Math.random() * 150);
				// }

				if (state.products_on_store.count_data.NUM_PAGE === 1) {
					state.products_on_store.list_products_on_store = []
					// DATA.map(elem => state.products_on_store.list_products_on_store.push(new ConstructorProd(elem, generatePrice())))
					state.products_on_store.list_products_on_store = DATA
				} else {
					state.products_on_store.list_products_on_store.push(...DATA)
				}
			}
			state.state_progress_circular_page = false
		},
		set_remains_on_store(state, response) {
			if (response.data.state) {
				const { COUNT, DATA } = response.data.log_data

				state.remains_on_store.count_data = COUNT

				if (state.remains_on_store.count_data.NUM_PAGE === 1) {
					state.remains_on_store.list_remains_on_store = DATA
				} else {
					state.remains_on_store.list_remains_on_store.push(...DATA)
				}
			}
			state.state_progress_circular_page = false
		},
		set_system_notification_result(state, server_response) {
			state.system_notification_dialog.state_dialog = true
			state.system_notification_dialog.header_message = server_response.state
				? 'Успешно выполнено'
				: 'Ошибка.'
			state.system_notification_dialog.body_message = server_response.message
			state.system_notification_dialog.body_sub_message = Array.isArray(
				server_response.sub_message,
			)
				? server_response.sub_message.reduce((a, b) => a + ' ' + b)
				: server_response.sub_message
			state.system_notification_dialog.state_server_response =
				server_response.state
			state.system_notification_dialog.color = server_response.state
				? 'green'
				: 'secondary'
			state.system_notification_dialog.http_response =
				server_response.http_response
		},
		set_system_notification_update(state, value) {
			state.system_notification_dialog.shouldUpdatePage = value
		},
		set_selected_type_products(state, object) {
			state.selected_type_or_subtype_products = object
		},
		set_types_contractors(state, object) {
			state.listTypeContractors = object
		},
		set_simple_modal(state, {message, data_obj, component_name}) {
			state.simpleModal.message = message
			state.simpleModal.data_obj = data_obj
			state.simpleModal.component_name = component_name
			state.simpleModal.state = true
		},
		hide_simple_modal(state){
			state.simpleModal.message = ''
			state.simpleModal.data_obj = {}
			state.simpleModal.component_name = ''
			state.simpleModal.state = false
			state.simpleModal.state_accept_event = false
		},
		accept_event_simple_modal(state){
			state.simpleModal.state_accept_event = true;
		},
		set_new_alert_specification(state, alert_data) {
			state.alerts.push(alert_data)
		},
	},
	modules: { specificationStore: specificationStore },
})
